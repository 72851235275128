import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  GetPlanOffersPayload,
  NotificationLevel,
  RetentionOffer,
} from '@mfe/shared/schema-types';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { AlertAction, setAlerts } from '@mfe/to-be-migrated/redux/alerts';

import { getNextBillingLifecycle, getSecondToLastDay } from './utils';

export function useHasOffersToDisplay(
  retentionOffers: RetentionOffer[] | null,
  planOffersData: GetPlanOffersPayload[] | null
): boolean {
  const { showRetentionOffers } = useSelector(selectConfig);
  const hasPlanOffers = hasOffers(planOffersData);
  const hasRetentionOffers = hasOffers(retentionOffers);

  return hasPlanOffers || (showRetentionOffers && hasRetentionOffers);

  function hasOffers(
    data: RetentionOffer[] | GetPlanOffersPayload[] | null
  ): boolean {
    return Boolean(data && data.length && data.length > 0);
  }
}

export function useDisplayDisconnectAlert() {
  const { t } = useTranslation('Disconnect');
  const dispatch = useDispatch();
  const billingAccount = useSelector(selectBillingAccount);
  const nextBillingCycleDate = getNextBillingLifecycle(billingAccount);
  const secondToLastDay = getSecondToLastDay(billingAccount);

  const displayDisconnectAlert = () =>
    dispatch(
      setAlerts([
        {
          level: NotificationLevel.Warning,
          title: `${t('cancelOrder.title')}`,
          caption: `${t('cancelOrder.label', {
            nextBillingCycleDate: nextBillingCycleDate,
            secondToLastDay: secondToLastDay,
          })}`,
          button: {
            action: AlertAction.Uncancel,
            label: `${t('cancelOrder.button')}`,
          },
          type: 'global',
        },
      ])
    );

  return displayDisconnectAlert;
}
