import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InlineLink } from '@vst/beam';

import {
  selectNetwork,
  setIsRebootModemModalOpen,
} from '@mfe/to-be-migrated/redux/network';
import useNavigate from '@mfe/services/navigation';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';
import { CustomStandardModal } from '@mfe/shared/components';

const RebootModemModal = ({ redirectRoute }: { redirectRoute: string }) => {
  const { t } = useTranslation('Network', { keyPrefix: 'rebootModem' });

  const { goTo } = useNavigate();
  const dispatch = useDispatch();

  const rebootModemHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    goTo(redirectRoute);
    dispatch(setIsRebootModemModalOpen(false));
  };

  const closeRebootModemModal = () => {
    dispatch(setIsRebootModemModalOpen(false));
  };

  const { isRebootModemModalOpen, isModemOnline } = useSelector(selectNetwork);

  const { isExtraSmall, isSmall } = useScreenResolution();

  const isMobile = isExtraSmall || isSmall;

  const phoneHref = `tel:${t(
    'offlineModemModal.callCustomerSupportPhoneNumber'
  )}`;

  const callUs = (
    <>
      {t('offlineModemModal.descriptionFirst')}
      {isMobile ? (
        <InlineLink href={phoneHref} data-cy="acp-call-service-unavailable">
          {t('offlineModemModal.callCustomerSupportPhoneNumberDisplay')}
        </InlineLink>
      ) : (
        t('offlineModemModal.callCustomerSupportPhoneNumberDisplay')
      )}
      {t('offlineModemModal.descriptionLast')}
    </>
  );

  if (!isModemOnline) {
    return (
      <CustomStandardModal
        isOpen={isRebootModemModalOpen}
        onClose={closeRebootModemModal}
        size="medium"
        header={t('offlineModemModal.title')}
        actions={{
          primary: {
            label: t('offlineModemModal.closeButton'),
            onClick: closeRebootModemModal,
          },
        }}
        surfaceProps={{ className: styles['modalContent'] }}
      >
        {callUs}
      </CustomStandardModal>
    );
  }

  return (
    <CustomStandardModal
      isOpen={isRebootModemModalOpen}
      onClose={closeRebootModemModal}
      size="medium"
      header={t('onlineModemModal.title')}
      actions={{
        primary: {
          label: t('onlineModemModal.rebootButton'),
          onClick: rebootModemHandler,
        },
        secondary: {
          label: t('onlineModemModal.cancelButton'),
          onClick: closeRebootModemModal,
        },
      }}
      surfaceProps={{ className: styles['modalContent'] }}
    >
      {t('onlineModemModal.description')}
    </CustomStandardModal>
  );
};

export default RebootModemModal;
