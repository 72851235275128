import { colors } from '@vst/beam';
import {
  Phone,
  Security,
  Construction,
  Work,
  OndemandVideo,
  Language,
  Wifi,
} from '@vst/beam-icons/icons';

import {
  ADDON_KIND,
  ADDON_PRODUCT_KIND,
  EASY_CARE_ADDON_KINDS,
  OFFICE_HOURS_ADDON_KINDS,
  SHIELD_ADDON_KINDS,
  STATIC_IP_ADDON_KINDS,
  VOICE_ADDONS_KINDS,
} from '@mfe/shared/util';

export type AddonIconType = {
  kind: string;
  className?: string;
};

export const AddonIcon = (props: AddonIconType) => {
  const { kind, className } = props;
  const { icon: Icon, color, backgroundColor } = getAddonImagePropsByKind(kind);

  return (
    <div style={{ color, backgroundColor }} className={className}>
      <Icon width={80} height={80} />
    </div>
  );
};

export const getAddonImagePropsByKind = (kind: string) => {
  if (kind === ADDON_PRODUCT_KIND.voice || VOICE_ADDONS_KINDS.includes(kind)) {
    return {
      backgroundColor: colors.blue['100'],
      color: colors.blue['600'],
      icon: Phone,
    };
  }

  if (kind === ADDON_PRODUCT_KIND.shield || SHIELD_ADDON_KINDS.includes(kind)) {
    return {
      backgroundColor: colors.warm['100'],
      color: colors.warm['600'],
      icon: Security,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.easyCare ||
    EASY_CARE_ADDON_KINDS.includes(kind)
  ) {
    return {
      backgroundColor: colors.green['100'],
      color: colors.green['600'],
      icon: Construction,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.officeHours ||
    OFFICE_HOURS_ADDON_KINDS.includes(kind)
  ) {
    return {
      backgroundColor: colors.pink['100'],
      color: colors.pink['500'],
      icon: Work,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.viasatStream ||
    kind === ADDON_KIND.CONTENT_ADD_ON
  ) {
    return {
      backgroundColor: colors.violet['100'],
      color: colors.violet['500'],
      icon: OndemandVideo,
    };
  }

  if (
    kind === ADDON_PRODUCT_KIND.staticIp ||
    STATIC_IP_ADDON_KINDS.includes(kind)
  ) {
    return {
      backgroundColor: colors.gray['200'],
      color: colors.gray['700'],
      icon: Language,
    };
  }

  return {
    backgroundColor: colors.blue['100'],
    color: colors.blue['600'],
    icon: Wifi,
  };
};
