import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Txt } from '@vst/beam';

import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { AvailablePlan, PlansLoading } from '@mfe/features/change-plan';
import { GetPlanOffersPayload } from '@mfe/shared/schema-types';
import { CustomStandardModal } from '@mfe/shared/components';
import { PlanLink } from '@mfe/legacy/mv/utils/Navigation';
import useNavigate from '@mfe/services/navigation';

import { ModalProps } from '../shared/types';
import styles from './styles.module.scss';

export const PlanOffers = ({
  handleSelect,
  isModalOpen,
  closeModal,
}: {
  handleSelect: () => void;
  isModalOpen: boolean;
  closeModal: () => void;
}) => {
  const { goTo } = useNavigate();
  const { t } = useTranslation('Disconnect');
  const { planOffersData, loading } = useSelector(selectChangePlan);

  const changePlanRoute = `${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`;
  const navigateToChangePlan = () => goTo(changePlanRoute);

  const hasOffers =
    planOffersData && planOffersData?.length && planOffersData?.length > 0;

  return (
    <>
      {loading ? (
        <div className={styles['availablePlans']}>
          <PlansLoading />
        </div>
      ) : hasOffers ? (
        <>
          <div>
            <Txt variant="bodyLargeBold">{t('planOffers.header')}</Txt>
            <Txt variant="bodySmallRegular" mt="8px">
              {t('planOffers.description')}
            </Txt>
          </div>

          <div className={styles['availablePlans']}>
            {planOffersData?.map((plan: GetPlanOffersPayload, i: number) => (
              <AvailablePlan key={i} plan={plan} handleClick={handleSelect} />
            ))}
          </div>
        </>
      ) : null}
      <PlanOfferConfirmationModal
        isOpen={isModalOpen}
        handleCancel={closeModal}
        handleContinue={navigateToChangePlan}
      />
    </>
  );
};

const PlanOfferConfirmationModal = ({
  isOpen,
  handleCancel,
  handleContinue,
}: ModalProps) => {
  const { t } = useTranslation('Disconnect');
  return (
    <CustomStandardModal
      surfaceProps={{ className: styles['planConfirmationModal'] }}
      size="medium"
      isOpen={isOpen}
      header={t('planOffers.modal.header')}
      actions={{
        primary: {
          label: t('button.continuePlanChange'),
          onClick: handleContinue,
        },
        secondary: { label: t('button.cancel'), onClick: handleCancel },
      }}
    >
      <Txt variant="bodyLargeRegular">{t('planOffers.modal.description')}</Txt>
    </CustomStandardModal>
  );
};
