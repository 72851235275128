import { addOnsSlice } from './addOns/addOnsSlice';
import { alertsSlice } from './alerts';
import { analyticsSlice } from './analytics';
import { authUserSlice } from './auth';
import { billingInfoSlice } from './billingInfo';
import { billingNavSlice } from './billingNav';
import { errorSlice } from './error';
import { eventSlice } from './events';
import { localeSlice } from './locale';
import { paymentsSlice } from './payments';
import { historicalUsageInfoSlice } from './usageHistory';
import { raygunSlice } from './raygun';
import { registrationSlice } from './registration';
import { scrubbedAddressSlice } from './scrubbedAddress';
import { streamOnSlice } from './streamOn';
import { streamOnPromoSlice } from './streamOnPromo';
import { userInfoSlice } from './userInfo';
import { utilsSlice } from './utils';
import { configSlice } from '@mfe/shared/redux/config';
import { updateAddressSlice } from './updateAddress';
import { pushNotificationsSlice } from './push-notifications';
import { buyMoreSlice } from './buyMore';
import { buyMoreHistorySlice } from './buyMoreHistory';
import { changePlanSlice } from './changePlan';
import { scheduleInstallationSlice } from './scheduleInstallation/scheduleInstallationSlice';
import { pendingInstallationSlice } from './pendingInstallation/pendingInstallationSlice';
import { updateBillCycleSlice } from './updateBillCycle/slice';
import { portfolioSlice } from './portfolio/portfolioSlice';
import { networkSlice } from './network/networkSlice';
import { planSlice } from './plan/planSlice';
import { broadbandLabelSlice } from './broadbandLabel/broadbandLabelSlice';
import { workOrdersSlice } from './workOrders/workOrdersSlice';
import { disconnectSlice } from './disconnect/slice';

const reducers = [
  authUserSlice,
  alertsSlice,
  utilsSlice,
  configSlice,
  updateAddressSlice,
  eventSlice,
  streamOnSlice,
  analyticsSlice,
  addOnsSlice,
  localeSlice,
  scrubbedAddressSlice,
  errorSlice,
  registrationSlice,
  raygunSlice,
  userInfoSlice,
  historicalUsageInfoSlice,
  streamOnPromoSlice,
  billingInfoSlice,
  billingNavSlice,
  paymentsSlice,
  buyMoreSlice,
  buyMoreHistorySlice,
  changePlanSlice,
  pushNotificationsSlice,
  scheduleInstallationSlice,
  pendingInstallationSlice,
  updateBillCycleSlice,
  portfolioSlice,
  networkSlice,
  planSlice,
  broadbandLabelSlice,
  networkSlice,
  workOrdersSlice,
  disconnectSlice,
];

const rootReducer = reducers.reduce((acc, curr) => {
  return { ...acc, [curr.name]: curr.reducer };
}, {});

export default rootReducer;

type SliceType = (typeof reducers)[number]; // Individual reducers type
type SliceNames = SliceType['name']; // Union of all reducer names

export type RootState = {
  [K in SliceNames]: K extends SliceType['name']
    ? ReturnType<Extract<SliceType, { name: K }>['reducer']>
    : never;
};
