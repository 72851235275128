import { useDispatch, useSelector } from 'react-redux';
import { FormEvent, useMemo, useState } from 'react';
import { AddressR0 } from '@viasat/res-apps-lib/build/types/vail';

import {
  selectUserInfo,
  updateContactInfo,
  updateContactInfoSMB,
} from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Role } from '@mfe/shared/schema-types';
import { selectScrubbedAddress } from '@mfe/to-be-migrated/redux/scrubbedAddress';

type FormAddress = {
  streetAddress: string;
  apt: string;
  city: string;
  state: string;
  postalCode: string;
};

export const useAddressFormValues = () => {
  const [inputAddress, setInputAddress] = useState<FormAddress>({
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    postalCode: '',
  });

  const parsedAddress = {
    addressLines: inputAddress.apt
      ? [inputAddress.streetAddress, inputAddress.apt]
      : [inputAddress.streetAddress],
    municipality: inputAddress.city,
    region: inputAddress.state,
    postalCode: inputAddress.postalCode,
    countryCode: 'US',
  };

  const handleAddressChange = (
    event: FormEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.currentTarget.value;
    setInputAddress((prev) => ({ ...prev, [field]: value }));
  };

  return { inputAddress: parsedAddress, handleAddressChange };
};

export const useEmailFormValues = () => {
  const [inputEmail, setInputEmail] = useState<string>('');

  const handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setInputEmail(event.currentTarget.value);
  };

  return { inputEmail, handleEmailChange };
};

export const usePrepopulateContactInfo = () => {
  const { userInfo, loading } = useSelector(selectUserInfo);
  const { email, accountHolder, accountType } = userInfo;
  const shippingAddress = userInfo.address.shipping as AddressR0;

  const { scrubbedAddress } = useSelector(selectScrubbedAddress);
  const { rawAddress } = scrubbedAddress;
  const { addressLines, ...rest } = rawAddress;
  const hasUpdatedValues = Object.values(rest).some((val) => val);
  const updatedAddress = scrubbedAddress?.rawAddress;

  const shippingAddressValues: string[] = useMemo(
    () =>
      formatAddressOnTwoLines(
        hasUpdatedValues ? updatedAddress : shippingAddress
      ),
    [hasUpdatedValues, updatedAddress, shippingAddress]
  );

  return {
    address: shippingAddressValues,
    email: accountType === AccountType.Smb ? accountHolder.email : email,
    loading,
  };
};

export function formatAddressOnTwoLines(address: AddressR0) {
  const { addressLines, region, postalCode, municipality } = address;
  const line1 =
    addressLines.length > 1 && addressLines[1]
      ? addressLines.join(', ')
      : addressLines[0];
  const line2 = `${municipality}, ${region} ${postalCode}`;
  return [line1, line2];
}

export const useSaveInIra = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUserInfo);
  const { accountType } = userInfo;
  const isSMB = accountType === AccountType.Smb;
  const action = isSMB ? updateContactInfoSMB : updateContactInfo;
  // Should only update primary email adress, not viasat_my_sso_ldap
  const shouldUpdateLoginUsername = false;

  const saveInIra = (contactInfo: any) => {
    const residentialPayload = { ...contactInfo, shouldUpdateLoginUsername };
    const smbPayload = { ...residentialPayload, role: Role.AccountHolder };
    const payload = isSMB ? smbPayload : residentialPayload;

    dispatch(action(payload));
  };

  return saveInIra;
};
