import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '@vst/beam-icons/illustrative-icons';
import { UpArrow } from '@vst/beam-icons/illustrative-icons';
import { CustomStandardModal } from '@mfe/shared/components';
import { IllustrativeIcon, Spinner, Surface, Txt } from '@vst/beam';
import {
  selectPurchaseRetentionOffers,
  setPurchaseRetentionOfferError,
} from '@mfe/to-be-migrated/redux/disconnect';

import styles from './styles.module.scss';
import { ModalProps } from '../shared/types';

interface PurchaseModalProps extends ModalProps {
  retentionOfferName: string;
}

const LoadingModal = ({ isOpen }: ModalProps) => {
  const { t } = useTranslation('Disconnect');
  return (
    <Surface className={styles.loadingModal} onClick={() => isOpen}>
      <div className={styles.loadingModalContent}>
        <Spinner size="medium" />
        <Txt>{t('retentionOffers.loadingModal.description')}</Txt>
      </div>
    </Surface>
  );
};

const ErrorModal = ({ isOpen, handleCancel }: ModalProps) => {
  const { t } = useTranslation('Disconnect');
  const dispatch = useDispatch();
  return (
    <CustomStandardModal
      isOpen={isOpen}
      onClose={handleCancel}
      size="medium"
      header={''}
      actions={{
        primary: {
          label: t('retentionOffers.errorModal.primaryButton'),
          onClick: () => {
            handleCancel?.();
            dispatch(setPurchaseRetentionOfferError(false));
          },
        },
      }}
    >
      <Surface className={styles.modalContentWrapper}>
        <IllustrativeIcon
          icon={Alert}
          color={'teal_600'}
          size={'80px'}
          mb={'16px'}
        />
        <Txt variant="heading5">{t('retentionOffers.errorModal.title')}</Txt>
        <Txt variant="bodySmallRegular" color="subtle">
          {t('retentionOffers.errorModal.description')}
        </Txt>
      </Surface>
    </CustomStandardModal>
  );
};

const SuccessModal = ({
  isOpen,
  retentionOfferName,
  handleCancel,
  handleContinue,
}: PurchaseModalProps) => {
  const { t } = useTranslation('Disconnect');
  return (
    <CustomStandardModal
      isOpen={isOpen}
      onClose={handleCancel}
      size={'medium'}
      header={''}
      actions={{
        primary: {
          label: `${t(
            'retentionOffers.confirmationModalSuccess.primaryButton'
          )}`,
          onClick: handleContinue,
        },
      }}
    >
      <Surface className={styles.modalContentWrapper}>
        <IllustrativeIcon
          icon={UpArrow}
          color="teal_600"
          size="80px"
          mb="16px"
        />
        <Txt variant="heading5">
          {t('retentionOffers.confirmationModalSuccess.title', {
            value: retentionOfferName,
          })}
        </Txt>
        <Txt variant="bodySmallRegular" color="subtle">
          {t('retentionOffers.confirmationModalSuccess.description')}
        </Txt>
      </Surface>
    </CustomStandardModal>
  );
};

export const PurchaseModal = ({
  isOpen,
  handleCancel,
  retentionOfferName,
  handleContinue,
}: PurchaseModalProps) => {
  const { error, loading, success } = useSelector(
    selectPurchaseRetentionOffers
  );
  if (loading) return <LoadingModal isOpen={isOpen} />;
  if (error) return <ErrorModal isOpen={isOpen} handleCancel={handleCancel} />;
  if (success) {
    return (
      <SuccessModal
        isOpen={isOpen}
        handleCancel={handleCancel}
        retentionOfferName={retentionOfferName}
        handleContinue={handleContinue}
      />
    );
  }

  return null;
};
