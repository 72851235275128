import { Spinner, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { CustomStandardModal } from '@mfe/shared/components';
import styles from './styles.module.scss';

type LoadingModalProps = {
  isOpen: boolean;
  ns: string;
};

export const LoadingModal = ({ isOpen, ns }: LoadingModalProps) => {
  const { t } = useTranslation(ns);
  return (
    <CustomStandardModal
      isOpen={isOpen}
      header={undefined}
      actions={{
        primary: { label: '', style: { display: 'none' } },
        secondary: undefined,
      }}
      surfaceProps={{ className: styles['loading-scrub-address-modal'] }}
    >
      <div>
        <Spinner
          description={<Txt color="subtle">{t('loading.description')}</Txt>}
        />
      </div>
    </CustomStandardModal>
  );
};
