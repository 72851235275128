import {
  AEMContentProvider,
  createAemUrl,
  AemFeature,
} from '@mfe/shared/components';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  AnalyticsEventNames,
  Categories,
  DataLayerEventName,
  pushToAdobeDataLayer,
} from '@mfe/to-be-migrated/redux/analytics';

const MvAppAd = () => {
  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    loading,
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const handleClick = () => {
    pushToAdobeDataLayer({
      event: DataLayerEventName.LINK,
      event_name: DataLayerEventName.LINK,
      event_label: AnalyticsEventNames.DOWNLOAD_APP_ICON_CLICKED,
      event_category: Categories.DownloadAppIcon,
    });
  };

  if (loading) {
    return null;
  }

  return (
    <button
      onClick={handleClick}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
      }}
    >
      <AEMContentProvider
        src={createAemUrl({
          baseUrl: aemBaseUrl,
          locale: userLocale,
          feature: AemFeature.MV_APP_AD,
          accountType,
        })}
      />
    </button>
  );
};

export default MvAppAd;
