import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SectionAlert, Surface, Txt } from '@vst/beam';

import {
  getAddonImagePropsByKind,
  HeaderWithBackNavigation,
} from '@mfe/shared/components';
import useNavigate from '@mfe/services/navigation';
import { OrderError } from '@mfe/features/add-ons';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { StackLink } from '@mfe/legacy/mv/utils/Navigation';
import {
  ADDON_PRODUCT_KIND,
  SESSION_STORAGE_VARIABLES,
  STATIC_IP_ADDON_KINDS,
  Storage,
  VOICE_ADDONS_KINDS,
} from '@mfe/shared/util';
import {
  disconnectViasatInternet,
  resetDisconnectInternet,
  selectDisconnectInternet,
  setHasCancelledDisconnectOrder,
} from '@mfe/to-be-migrated/redux/disconnect';

import {
  getNextBillingLifecycle,
  hasEquipmentAlert,
  hexToRgba,
  shouldRenderAddonDisclosure,
} from '../shared/utils';
import { useDisplayDisconnectAlert } from '../shared/hooks';
import { PageLayout } from '../shared/page-layout';
import { DisconnectionCart } from '../shared/disconnection-cart';
import { Footer } from '../shared/footer';
import { LoadingCard } from '../shared/loading-card';
import { usePrepopulateContactInfo } from '../step-1/utils';

import styles from './styles.module.scss';

export const DisconnectStep3 = ({
  handleCancel,
  step,
}: {
  handleCancel: () => void;
  step: number;
}) => {
  const { t } = useTranslation(['Disconnect']);
  const dispatch = useDispatch();
  const { replace } = useNavigate();

  const {
    loading,
    error,
    success: hasCancelledPlan,
  } = useSelector(selectDisconnectInternet);
  const billingAccount = useSelector(selectBillingAccount);
  const nextBillingCycleDate = getNextBillingLifecycle(billingAccount);

  const displayDisconnectAlert = useDisplayDisconnectAlert();

  React.useEffect(() => {
    if (hasCancelledPlan) {
      Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
      replace(`/${StackLink.Profile}`);
      displayDisconnectAlert();
    }
  }, [hasCancelledPlan]);

  const handleContinue = () => {
    dispatch(setHasCancelledDisconnectOrder(false));
    Storage.removeItem(SESSION_STORAGE_VARIABLES.DISMISSED_ALERTS);
    dispatch(disconnectViasatInternet(nextBillingCycleDate));
  };

  if (error) {
    return (
      <OrderError
        title={t('Error.Title')}
        handlePrimaryAction={() => dispatch(resetDisconnectInternet())}
      />
    );
  }

  return (
    <PageLayout
      step={step}
      header={
        <HeaderWithBackNavigation
          title={t('page.title.review')}
          goBackLabel={t('button.back')}
          handleBackNavigation={handleCancel}
        />
      }
      leftContent={
        <LeftContent
          handleContinue={handleContinue}
          handleCancel={handleCancel}
          isRemoveViasatLoading={loading}
        />
      }
      rightContent={<DisconnectionCart />}
    />
  );
};

const LeftContent = ({
  handleCancel,
  handleContinue,
  isRemoveViasatLoading,
}: {
  handleCancel: () => void;
  handleContinue: () => void;
  isRemoveViasatLoading: boolean;
}) => {
  const { t } = useTranslation(['Disconnect', 'Global']);

  const { user } = useSelector(selectUser);

  const { indoorUnit } = user;
  const showEquipmentAlert = hasEquipmentAlert(indoorUnit, t);

  return (
    <>
      <Surface
        variant="primary"
        radius="16px"
        className={styles['leftCardContent']}
      >
        <div>
          <Txt mb="8px" variant="bodyLargeBold">
            {t('step3.leftTitle1')}
          </Txt>
          <Txt variant="bodySmallRegular">{t('step3.leftText1')}</Txt>
        </div>
        <hr className={styles['separator']} />
        <ReviewContactInformation />
        {showEquipmentAlert && (
          <SectionAlert className={styles['sectionAlert']}>
            <div>
              <Txt component="span" variant="smallBold" color="regular">
                {t('contactInfo.notice')}
              </Txt>
              <Txt component="span" variant="smallRegular" color="regular">
                {t('contactInfo.alert')}
              </Txt>
            </div>
          </SectionAlert>
        )}
        <div>
          <Txt mb="8px" variant="bodyLargeBold">
            {t('step3.plans.headlineTitle')}
          </Txt>
          <Txt variant="bodySmallRegular">{t('step3.plans.headlineText')}</Txt>
        </div>
        <hr className={styles['separator']} />
        <ReviewPlans />
      </Surface>
      <Footer>
        <Button variant="tertiary" onClick={handleCancel}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="destructive"
          onClick={handleContinue}
          disabled={isRemoveViasatLoading}
        >
          {t('step3.primaryCTA')}
        </Button>
      </Footer>
    </>
  );
};

const ReviewContactInformation = () => {
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation('Disconnect');
  const { address, email } = usePrepopulateContactInfo();

  return (
    <Surface
      p="16px"
      radius="8px"
      variant="primary"
      className={styles['contactInfoCard']}
    >
      <Surface style={{ width: '100%' }}>
        <Txt variant="labelSmall" mb="4px">
          {t('step3.accountTitle')}
        </Txt>
        <Txt variant="bodySmallRegular">
          {t('step3.accountInfo')} {userInfo.user.accountNumber}
        </Txt>
      </Surface>

      <div style={{ width: '100%' }}>
        <Txt variant="labelSmall" mb="4px">
          {t('step3.shippingAddress')}
        </Txt>
        <Txt variant="bodySmallRegular">{address[0]}</Txt>
        <Txt variant="bodySmallRegular">{address[1]}</Txt>
      </div>

      <div style={{ width: '100%' }}>
        <Txt variant="labelSmall" mb="4px">
          {t('step3.emailAddress')}
        </Txt>
        <Txt variant="bodySmallRegular">{email}</Txt>
      </div>
    </Surface>
  );
};

const ReviewPlans = () => {
  const { currentAddOns, loading: addonsLoading } = useSelector(selectAddOns);
  const { characteristics, loading: planLoading } = useSelector(
    selectPlanCharacteristics
  );

  if (addonsLoading || planLoading) return <LoadingCard />;

  return (
    <>
      <DisclosureCard
        name={characteristics.name}
        kind={ProductInstanceTypes.Internet}
      />
      {currentAddOns?.map(({ name, kind }) =>
        shouldRenderAddonDisclosure(kind) ? (
          <DisclosureCard key={name} name={name} kind={kind} />
        ) : null
      )}
    </>
  );
};

const DisclosureCard = ({ name, kind }: { name: string; kind: string }) => {
  const { backgroundColor, icon: Icon, color } = getAddonImagePropsByKind(kind);
  const hexBackgroundColor = hexToRgba(backgroundColor, 0.3);
  const iconHexBackgroundColor = hexToRgba(backgroundColor, 0);

  const listItems = useDisclosureListItems(kind);

  return (
    <div
      style={{ background: `${hexBackgroundColor}` }}
      className={styles['disclosure']}
    >
      <div
        style={{ color, backgroundColor: iconHexBackgroundColor }}
        className={styles['icon']}
      >
        <Icon width={80} height={80} />
      </div>
      <ProductDisclosure name={name} listItems={listItems} />
    </div>
  );
};

const useDisclosureListItems = (kind: string) => {
  const { t } = useTranslation('Disconnect');
  const billingAccount = useSelector(selectBillingAccount);
  const nextBillingCycleDate = getNextBillingLifecycle(billingAccount);

  if (kind === ADDON_PRODUCT_KIND.voice || VOICE_ADDONS_KINDS.includes(kind)) {
    return [
      nextBillingCycleDate
        ? t('disclosure.voice1', { nextBillingCycleDate })
        : t('cart.disconnectDate.descriptionError'),
      t('disclosure.voice2'),
      t('disclosure.voice3'),
      t('disclosure.voice4'),
    ];
  }

  if (
    kind === ADDON_PRODUCT_KIND.staticIp ||
    STATIC_IP_ADDON_KINDS.includes(kind)
  ) {
    return [
      nextBillingCycleDate
        ? t('disclosure.staticIP', { nextBillingCycleDate })
        : t('cart.disconnectDate.descriptionError'),
    ];
  }

  if (
    kind === ProductInstanceTypes.Internet ||
    kind === ProductInstanceTypes.WirelessInternet
  )
    return [
      nextBillingCycleDate
        ? t('disclosure.internet1', { nextBillingCycleDate })
        : t('cart.disconnectDate.descriptionError'),
      t('disclosure.internet2'),
      t('disclosure.internet3'),
    ];

  return [];
};

const ProductDisclosure = ({
  name,
  listItems,
}: {
  name: string;
  listItems: string[];
}) => {
  return (
    <div className={styles['unorderedList']}>
      <Txt variant="bodySmallBold">{name}</Txt>
      <ul style={{ margin: 0, paddingLeft: 20 }}>
        {listItems.map((item: string, index: number) => (
          <li key={index}>
            <Txt variant="bodySmallRegular">{item}</Txt>
          </li>
        ))}
      </ul>
    </div>
  );
};
