import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type StepCounterProps = {
  step: number;
  maxSteps: number;
};

export const StepCounter = ({ step, maxSteps }: StepCounterProps) => {
  const { t } = useTranslation('ShopAddons');
  const width = `${(step / maxSteps) * 100}%`;
  return (
    <div data-cy="steps-container" className={styles['steps-container']}>
      <Txt data-cy="steps-verbiage" variant="bodySmallRegular" color="subtle">
        {t('stepsHeader', { step, maxSteps })}
      </Txt>
      <div className={styles['steps-background']}>
        <div
          data-cy="steps-bar"
          className={styles['steps-bar']}
          style={{ width }}
        />
      </div>
    </div>
  );
};
