import { Surface } from '@vst/beam';
import { useSelector } from 'react-redux';

import { StepCounter } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { selectRetentionOffers } from '@mfe/to-be-migrated/redux/disconnect';

import { useHasOffersToDisplay } from './hooks';
import { CollapsibleDisconectCart } from './collapsible-cart';

import styles from './styles.module.scss';

type PageLayoutProps = {
  header: React.ReactNode;
  step: number;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
};

export const PageLayout = ({
  header,
  step,
  leftContent,
  rightContent,
  children,
}: PageLayoutProps) => {
  const { isMedium, isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall || isMedium;

  const { data: retentionOffers } = useSelector(selectRetentionOffers);
  const { planOffersData } = useSelector(selectChangePlan);

  const shouldRenderRetentionStep = useHasOffersToDisplay(
    retentionOffers,
    planOffersData
  );

  const totalSteps = shouldRenderRetentionStep ? 3 : 2;

  return (
    <>
      {isMobile && <CollapsibleDisconectCart />}
      <div className={styles['page']}>
        <div className={styles['header']}>
          {header}
          <StepCounter step={step} maxSteps={totalSteps} />
        </div>

        <div className={styles['content']}>
          {leftContent && (
            <Surface radius="16px" className={styles['leftCard']}>
              {leftContent}
            </Surface>
          )}
          {rightContent && !isMobile && (
            <Surface radius="16px" p="48px" className={styles['rightCard']}>
              {rightContent}
            </Surface>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
