import { FC } from 'react';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { CustomStandardModal } from '@mfe/shared/components';
import styles from './styles.module.scss';

type IncorrectAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  ns: string;
};

export const IncorrectAddressModal: FC<IncorrectAddressModalProps> = ({
  isOpen,
  onClose,
  ns,
}) => {
  const { t } = useTranslation(ns);

  return (
    <div>
      <CustomStandardModal
        size={'medium'}
        header={t('incorrect.title')}
        isOpen={isOpen}
        onClose={onClose}
        actions={{
          primary: {
            label: t('incorrect.button.primary'),
            onClick: onClose,
          },
        }}
        surfaceProps={{ className: styles['incorrect-address-modal'] }}
      >
        <Txt variant={'bodySmallRegular'} style={{ margin: '0px' }}>
          {t('incorrect.description')}
        </Txt>
      </CustomStandardModal>
    </div>
  );
};
