import { useState } from 'react';
import { Button, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectRetentionOffers,
  getPurchaseRetentionOffers,
} from '@mfe/to-be-migrated/redux/disconnect';
import useNavigate from '@mfe/services/navigation';
import { setAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { SESSION_STORAGE_VARIABLES, Storage } from '@mfe/shared/util';
import { selectLocation, selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  RetentionOffer as RetentionOfferType,
  NotificationLevel,
} from '@mfe/shared/schema-types';

import styles from './styles.module.scss';
import { PurchaseModal } from './purchase-modal';
import { RetentionOfferConfirmationModal } from './confirmation-modal';

export const RetentionOffers = ({
  isModalOpen,
  closeModal,
  handleSelect,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  handleSelect: () => void;
}) => {
  const { goTo } = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');

  const {
    user: { productInstanceId },
  } = useSelector(selectUser);
  const { data: retentionOffers, loading } = useSelector(selectRetentionOffers);
  const { coordinates, address } = useSelector(selectLocation) || {};
  const [selectedOffer, setSelectedOffer] =
    useState<Partial<RetentionOfferType> | null>(null);
  const { offer_id, id, name } = selectedOffer || {};
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const navigateToPlanTabAccount = () => {
    Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
    goTo('Profile');

    dispatch(
      setAlerts([
        {
          level: NotificationLevel.Success,
          title: `${t('retentionOffers.banner.title')}`,
          caption: `${t('retentionOffers.banner.description')}`,
          type: 'global',
        },
      ])
    );
  };

  const hasOffers =
    retentionOffers && retentionOffers?.length && retentionOffers?.length > 0;

  const handleSelectOffer = () => {
    if (!offer_id || !id || !productInstanceId) {
      console.error('Missing required fields for purchase retention offer');
      return;
    }
    dispatch(
      getPurchaseRetentionOffers({
        shoppingCartId: offer_id,
        productTypeId: id,
        rootProductTypeId: productInstanceId,
        coordinates: {
          latitude: coordinates?.latitude ?? 0,
          longitude: coordinates?.longitude ?? 0,
        },
        address: {
          addressLines: address?.addressLines ?? [],
          municipality: address?.municipality ?? '',
          region: address?.region ?? '',
          postalCode: address?.postalCode ?? '',
          countryCode: address?.countryCode ?? '',
        },
      })
    );
    setShowConfirmationModal(false);
  };
  return (
    <>
      {loading ? (
        <div className={styles['retentionOffers']}>
          <RetentionOfferLoading />
          <RetentionOfferLoading />
        </div>
      ) : hasOffers ? (
        <>
          <div>
            <Txt variant="bodyLargeBold">{t('retentionOffers.header')}</Txt>
            <Txt variant="bodySmallRegular" mt="8px">
              {t('retentionOffers.description')}
            </Txt>
          </div>
          <div className={styles['retentionOffers']}>
            {retentionOffers?.map(
              (offer: Partial<RetentionOfferType>, index: number) => (
                <RetentionOffer
                  key={index}
                  name={offer.name}
                  description={offer.description}
                  handleSelect={() => {
                    handleSelect();
                    setSelectedOffer(offer);
                    setShowConfirmationModal(true);
                  }}
                />
              )
            )}
          </div>
        </>
      ) : null}
      {showConfirmationModal && (
        <RetentionOfferConfirmationModal
          isOpen={isModalOpen}
          retentionOfferName={name || ''}
          handleCancel={closeModal}
          handleContinue={handleSelectOffer}
        />
      )}
      <PurchaseModal
        isOpen={isModalOpen}
        handleCancel={closeModal}
        retentionOfferName={name || ''}
        handleContinue={navigateToPlanTabAccount}
      />
    </>
  );
};

const RetentionOfferLoading = () => {
  return (
    <div className={styles['retentionOfferLoading']}>
      <div id="details">
        <div id="name" />
        <div id="description" />
      </div>
      <div id="button" />
    </div>
  );
};

type RetentionOfferProps = {
  name: string | undefined | null;
  description: string | undefined | null;
  handleSelect: () => void;
};

const RetentionOffer = ({
  name,
  description,
  handleSelect,
}: RetentionOfferProps) => {
  const { t } = useTranslation('Disconnect');

  return (
    <div className={styles['retentionOffer']}>
      <div className={styles['retentionOfferDetails']}>
        {name && <Txt variant="bodySmallBold">{name}</Txt>}
        {description && <Txt variant="smallRegular">{description}</Txt>}
      </div>
      <Button variant="secondary" onClick={handleSelect}>
        {t('button.select')}
      </Button>
    </div>
  );
};
