import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  GetCurrentUsagePayload,
  GetPlanCharacteristicsPayload,
  GetDownloadSpeedsPayload,
} from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

import {
  initialUsageState,
  initialPriceState,
  initialCharacteristicsState,
  initialDownloadSpeedsState,
} from './initialStates';

export type ErrorType = ErrorsObj | RuntimeError | null;

export type PlanState = {
  data: {
    usage: GetCurrentUsagePayload;
    price: string;
    characteristics: GetPlanCharacteristicsPayload;
    downloadSpeeds: GetDownloadSpeedsPayload;
    tier: number | null;
  };
  loading: {
    usage: boolean;
    price: boolean;
    characteristics: boolean;
    downloadSpeeds: boolean;
    tier: boolean;
  };
  errors: {
    usage: ErrorType;
    price: ErrorType;
    characteristics: ErrorType;
    downloadSpeeds: ErrorType;
    tier: ErrorType;
  };
};

export const initialPlanState: PlanState = {
  data: {
    usage: initialUsageState,
    price: initialPriceState,
    characteristics: initialCharacteristicsState,
    downloadSpeeds: initialDownloadSpeedsState,
    tier: null,
  },
  loading: {
    usage: false,
    price: false,
    characteristics: false,
    downloadSpeeds: false,
    tier: false,
  },
  errors: {
    usage: null,
    price: null,
    characteristics: null,
    downloadSpeeds: null,
    tier: null,
  },
};

export const planSlice = createSlice({
  name: 'plan',
  initialState: initialPlanState,
  reducers: {
    getUsage: (state) => {
      state.loading.usage = true;
    },
    setUsage: (state, action: PayloadAction<GetCurrentUsagePayload>) => {
      state.data.usage = action.payload;
      state.loading.usage = false;
    },
    setUsageError: (state, action) => {
      state.errors.usage = action.payload;
      state.loading.usage = false;
    },
    refetchUsage: (state) => {
      state.loading.usage = true;
    },
    getCharacteristics: (state) => {
      state.loading.characteristics = true;
    },
    setCharacteristics: (state, action) => {
      state.data.characteristics = action.payload;
      state.loading.characteristics = false;
    },
    setCharacteristicsError: (state, action) => {
      state.errors.characteristics = action.payload;
      state.loading.characteristics = false;
    },
    getPrice: (state) => {
      state.loading.price = true;
    },
    setPrice: (state, action) => {
      state.data.price = action.payload;
      state.loading.price = false;
    },
    setPriceError: (state, action) => {
      state.errors.price = action.payload;
      state.loading.price = false;
    },
    getDownloadSpeeds: (state) => {
      state.loading.downloadSpeeds = true;
    },
    setDownloadSpeeds: (state, action) => {
      state.data.downloadSpeeds = action.payload;
      state.loading.downloadSpeeds = false;
    },
    setDownloadSpeedsError: (state, action) => {
      state.errors.downloadSpeeds = action.payload;
      state.loading.downloadSpeeds = false;
    },
    setLoadingTier: (state) => {
      state.loading.tier = true;
    },
    setTier: (state, action) => {
      state.data.tier = action.payload;
      state.loading.tier = false;
    },
    setTierError: (state, action) => {
      state.errors.tier = action.payload;
      state.loading.tier = false;
    },
    setUsageLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.usage = action.payload;
    },
  },
});

export const {
  getUsage,
  setUsage,
  setUsageError,
  refetchUsage,
  getCharacteristics,
  setCharacteristics,
  setCharacteristicsError,
  getPrice,
  setPrice,
  setPriceError,
  getDownloadSpeeds,
  setDownloadSpeeds,
  setDownloadSpeedsError,
  setTier,
  setTierError,
  setLoadingTier,
  setUsageLoading,
} = planSlice.actions;

export const selectPlanInfo = (state: { plan: PlanState }) => ({
  ...state.plan,
});

export const selectCurrentUsage = (state: { plan: PlanState }) => ({
  data: state.plan.data.usage,
  loading: state.plan.loading.usage,
  errors: state.plan.errors.usage,
});

export const selectPlanCharacteristics = (state: { plan: PlanState }) => ({
  characteristics: state.plan.data.characteristics,
  loading: state.plan.loading.characteristics,
  errors: state.plan.errors.characteristics,
});

export const selectDownloadSpeeds = (state: { plan: PlanState }) => ({
  data: state.plan.data.downloadSpeeds,
  loading: state.plan.loading.downloadSpeeds,
  errors: state.plan.errors.downloadSpeeds,
});

export const selectCurrentPlanPrice = (state: { plan: PlanState }) => ({
  price: state.plan.data.price,
  loading: state.plan.loading.price,
  errors: state.plan.errors.price,
});

export const selectUsageTier = (state: { plan: PlanState }) => ({
  tier: state.plan.data.tier,
  loading: state.plan.loading.tier,
  errors: state.plan.errors.tier,
});
