import { Button, InlineLink, SectionAlert, Surface, Txt } from '@vst/beam';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectRetentionOffers } from '@mfe/to-be-migrated/redux/disconnect';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import { scrollToTop } from '@mfe/shared/redux/utils';
import { selectConfig } from '@mfe/shared/redux/config';

import { Footer } from '../shared/footer';
import { DisconnectSteps } from '../shared/types';
import { PageLayout } from '../shared/page-layout';
import { useHasOffersToDisplay } from '../shared/hooks';
import { PlanOffers } from './plan-offers';
import { RetentionOffers } from './retention-offers';
import styles from './styles.module.scss';

export const DisconnectStep2 = ({
  setStep,
}: {
  setStep: Dispatch<SetStateAction<DisconnectSteps>>;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');

  const handleBackNavigation = () => setStep(DisconnectSteps.INTRO);

  const handleContinue = () => {
    setStep(DisconnectSteps.REVIEW);
    dispatch(scrollToTop());
  };

  return (
    <PageLayout
      step={2}
      header={
        <HeaderWithBackNavigation
          title={t('page.title.retention')}
          goBackLabel={t('button.back')}
          handleBackNavigation={handleBackNavigation}
        />
      }
    >
      <Content handleContinue={handleContinue} />
    </PageLayout>
  );
};

enum ConfirmationModal {
  PLAN = 'PLAN',
  RETENTION = 'RETENTION',
}

const Content = ({ handleContinue }: { handleContinue: () => void }) => {
  const { t } = useTranslation('Disconnect');

  const { planOffersData, error: planOffersError } =
    useSelector(selectChangePlan);

  const { data: retentionOffers, error: retentionOffersError } = useSelector(
    selectRetentionOffers
  );
  const { showRetentionOffers } = useSelector(selectConfig);

  const hasOffers = useHasOffersToDisplay(retentionOffers, planOffersData);
  const hasErrors = planOffersError && retentionOffersError;

  const [modal, setModal] = useState<ConfirmationModal | null>(null);

  const closeModal = () => setModal(null);
  const handleSelectPlan = () => setModal(ConfirmationModal.PLAN);
  const handleSelectRetentionOffer = () =>
    setModal(ConfirmationModal.RETENTION);

  return (
    <Surface variant="primary" radius="16px" className={styles['content']}>
      {hasOffers && (
        <div className={styles['body']}>
          <PlanOffers
            isModalOpen={modal === ConfirmationModal.PLAN}
            handleSelect={handleSelectPlan}
            closeModal={closeModal}
          />
          {showRetentionOffers && (
            <RetentionOffers
              isModalOpen={modal === ConfirmationModal.RETENTION}
              handleSelect={handleSelectRetentionOffer}
              closeModal={closeModal}
            />
          )}
        </div>
      )}
      {hasErrors && (
        <div className={styles['body']}>
          <SectionAlert variant="warning" className={styles['errorAlert']}>
            <CustomerSupportCTA />
          </SectionAlert>
        </div>
      )}
      <Footer>
        <Button onClick={handleContinue}>
          {t('button.skipOfferAndContinue')}
        </Button>
      </Footer>
    </Surface>
  );
};

const CustomerSupportCTA = () => {
  const { t } = useTranslation('Disconnect');
  return (
    <div className={styles['customerSupportCta']}>
      <Txt component="span" variant="bodySmallRegular">
        {t('retentionOffers.error.descriptionStart')}
      </Txt>
      <InlineLink
        variant="primary"
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
      <Txt component="span" variant="bodySmallRegular">
        {t('retentionOffers.error.descriptionEnd')}
      </Txt>
    </div>
  );
};
