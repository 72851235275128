import { useDispatch, useSelector } from 'react-redux';
import {
  Spinner,
  SectionAlert,
  Txt,
  StandAloneLink,
  useToast,
} from '@vst/beam';

import {
  resetScrubbedAddress,
  selectScrubbedAddress,
} from '@mfe/to-be-migrated/redux/scrubbedAddress';
import { UpdateAddressForm } from './update-address-form';
import { AddressSelection } from './address-selection';
import {
  AddressType,
  setAddressLine1,
  setAddressLine2,
  setPostalCode,
  setMunicipality,
  setState,
  setCountryCode,
  setAddressType,
  selectUpdateAddress,
  submitAddress,
  updateAddress,
  resetAddress,
} from '@mfe/to-be-migrated/redux/updateAddress';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomStandardModal } from '../standard-modal';

type UpdateAdressProp = {
  type: AddressType;
  isOpen: boolean;
  toggleModal: () => void;
};

export const UpdateAddress = ({
  isOpen,
  toggleModal,
  type,
}: UpdateAdressProp) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('UpdateAddress');
  const { toasts, removeOldestToast, addToast } = useToast();
  const { loading, scrubbedAddress } = useSelector(selectScrubbedAddress);
  const {
    userInfo: {
      address: { shipping, billing },
    },
  } = useSelector(selectUserInfo);
  const { successful } = useSelector(selectUpdateAddress);

  const address = type === 'shipping' ? shipping : billing;

  useEffect(() => {
    dispatch(setAddressLine1(address?.addressLines[0] || ''));
    dispatch(setAddressLine2(address?.addressLines[1] || ''));
    dispatch(setState(address?.region || ''));
    dispatch(setMunicipality(address?.municipality || ''));
    dispatch(setPostalCode(address?.postalCode?.toString() || ''));
    dispatch(setCountryCode(address?.countryCode || 'US'));
    dispatch(setAddressType(type));
  }, [dispatch, address]);

  useEffect(() => {
    if (successful) {
      if (toasts.length) removeOldestToast();
      addToast({
        variant: 'success',
        header: t('toast.header'),
        body: t('toast.body'),
        closable: false,
        duration: 3000,
      });
      dispatch(resetScrubbedAddress());
      dispatch(resetAddress());
      toggleModal();
    }
  }, [dispatch, successful]);

  const renderBody = () => {
    //Don't change me, I need to be a function to avoid re-rendering
    if (loading) return <Spinner />;

    if (scrubbedAddress.formattedAddress) return <AddressSelection />;

    return <UpdateAddressForm />;
  };

  const handleClick = () => {
    if (scrubbedAddress.formattedAddress) {
      dispatch(updateAddress());
    } else {
      dispatch(submitAddress());
    }
  };

  const actions = {
    primary: {
      label: t('buttonLabels.saveAddress'),
      onClick: handleClick,
      style: { minWidth: '140px', marginRight: '0px' },
    },
    secondary: scrubbedAddress.formattedAddress
      ? {
          label: t('buttonLabels.editAddress'),
          onClick: () => {
            dispatch(resetScrubbedAddress());
          },
        }
      : undefined,
  };

  return (
    <CustomStandardModal
      isOpen={isOpen}
      header={t('form.header')}
      size="medium"
      onClose={() => {
        dispatch(resetScrubbedAddress());
        toggleModal();
      }}
      actions={actions}
      tertiaryAction={
        <StandAloneLink
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(resetScrubbedAddress());
            toggleModal();
          }}
        >
          {t('buttonLabels.cancel')}
        </StandAloneLink>
      }
    >
      <div>
        <SectionAlert
          mb="24px"
          variant="infoPrimary"
          contentSlot={
            <div>
              <Txt variant="smallBold" component="span" color="regular">
                {t('alert.pleaseNote')}
              </Txt>
              <Txt variant="smallRegular" component="span" color="regular">
                {t('alert.body')}
              </Txt>
            </div>
          }
        />
        {renderBody()}
      </div>
    </CustomStandardModal>
  );
};
