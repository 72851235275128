import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';

import { StoreProvider } from '../context/store';
import { DailyUsageChart } from '../presenters/DailyUsageChart';
import { TotalUsageChart } from '../presenters/TotalUsageChart';
import { UnleashedDailyUsageChart } from '../presenters/DailyUsageChart/UnleashedDailyUsageChart';
import { hasUnlimitedAverage } from '@mfe/shared/util';
import { ProductInstanceStatus } from '@mfe/shared/schema-types';

export const UsageHistory = (): JSX.Element | null => {
  const [showMonthly, setShowMonthly] = useState(true);
  const [selectedBillCycle, setSelectedBillCycle] = useState(0);
  const [showAlertMessage, setShowAlertMessage] = useState(false);

  const { showDonutChart, showUnleashedUsage } = useSelector(selectConfig);
  const {
    characteristics: { hasUnlimitedUsageMeter, usageMeter, isUnleashed },
  } = useSelector(selectPlanCharacteristics);

  const {
    user: { productInstanceStatus },
  } = useSelector(selectUser);

  const hasUnlimitedAverageMeter =
    showUnleashedUsage && hasUnlimitedAverage(usageMeter);

  const hasUnleashedPlan = hasUnlimitedAverageMeter || isUnleashed;

  const isPreInstall =
    productInstanceStatus === ProductInstanceStatus.Preinstall;

  const displayDonutChart =
    showMonthly && showDonutChart && !hasUnlimitedUsageMeter && !isPreInstall;

  if (showUnleashedUsage && hasUnleashedPlan) {
    return <UnleashedDailyUsageChart />;
  }

  return (
    <StoreProvider
      value={{
        selectedBillCycle,
        showMonthly,
        showAlertMessage,
        setSelectedBillCycle,
        setShowMonthly,
        setShowAlertMessage,
      }}
    >
      {displayDonutChart ? <TotalUsageChart /> : <DailyUsageChart />}
    </StoreProvider>
  );
};

export default UsageHistory;
