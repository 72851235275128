import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  loginFromCode,
  loginFromCookie,
  selectUser,
  sendAuthParams,
  TOKEN_INFO_COOKIE_NAME,
  getAuthUrls,
} from '@mfe/to-be-migrated/redux/auth';
import StorageManager from '@mfe/legacy/mv/services/localStorage';
import { ServerError } from '@mfe/legacy/mv/views/ServerError';
import { TokenContext } from '@mfe/legacy/mv/views/Auth';
import { retrieveAuthCode, getAuthVars } from '@mfe/legacy/mv/views/Auth/utils';
import { setOrigin } from '@mfe/to-be-migrated/redux/events';
import { AuthEventTypes } from '@mfe/services/window-messages';
import {
  triggerAfterUserIdSet,
  useTrackEventFunction,
} from '@mfe/legacy/mv/containers';
import { Storage, SESSION_STORAGE_VARIABLES } from '@mfe/shared/util';

import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const REDIRECT_PATH_COOKIE_NAME = 'redirectPath';

export const WebAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [showAuthError, setShowAuthError] = useState(false);
  const { platform, env: mvEnv, mfeEnv, locale } = getAuthVars();

  const dispatch = useDispatch();
  const trackEvent = useTrackEventFunction();

  useEffect(() => {
    dispatch(
      sendAuthParams({
        env: mvEnv,
        platform,
        mfeEnv,
        locale,
      })
    );
    dispatch(setOrigin({ origin: window.location.origin }));
  }, [dispatch, mvEnv, platform, mfeEnv, locale]);

  const {
    user: {
      auth: { tokenInfo, isAuthenticated, isAuthMFELoaded },
    },
  } = useSelector(selectUser);

  const {
    auth: { client, baseUrl },
  } = useSelector(selectConfig);

  const logout = () => {
    setShowAuthError(false);
    const { logoutUrl } = getAuthUrls(client, baseUrl, tokenInfo.idToken);
    StorageManager.deleteAll().finally((): void => {
      Storage.removeItem(SESSION_STORAGE_VARIABLES.HIDE_ACP_BANNER);
      Storage.removeItem(SESSION_STORAGE_VARIABLES.ACP_REGISTERED);
      Storage.removeItem(SESSION_STORAGE_VARIABLES.DISMISSED_ALERTS);
      Storage.removeItem(SESSION_STORAGE_VARIABLES.CHANGE_PLAN_SUCCESS);
      Storage.removeItem(SESSION_STORAGE_VARIABLES.PURCHASED_PRODUCT_TYPE_ID);

      window.location.assign(logoutUrl);
    });
  };

  useEffect(() => {
    const handleMessage = (message: any): any => {
      const messageData = message.data;
      if (
        messageData.domain === 'General' &&
        messageData.eventType === AuthEventTypes.LoginEvent
      ) {
        const isReloaded =
          performance?.getEntriesByType('navigation')?.length &&
          (
            performance?.getEntriesByType(
              'navigation'
            )[0] as PerformanceNavigationTiming
          )?.type === 'reload';

        if (isReloaded)
          return window.removeEventListener('message', handleMessage);

        triggerAfterUserIdSet(() => {
          trackEvent('Login', 'successful', {
            property:
              messageData.data.loginType === 'cookie' ? 'token' : 'password',
          });
        });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const login = (url?: string) => {
    if (url) {
      const code = retrieveAuthCode(url, client.redirectUrl ?? '') ?? '';
      if (code) {
        dispatch(loginFromCode({ code, locale }));

        return;
      }
    }
    StorageManager.get(TOKEN_INFO_COOKIE_NAME).then((stringifiedTokenInfo) => {
      dispatch(
        loginFromCookie({ stringifiedTokenInfo: stringifiedTokenInfo ?? '' })
      );
    });
  };

  return (
    <TokenContext.Provider
      value={{
        token: tokenInfo,
        logout,
        setIsAuthenticated: () => undefined,
        setToken: () => undefined,
        isAuthenticated,
        isAuthMFELoaded,
        cookieLoginFailed: false,
        redirectUrl: client.redirectUrl ?? undefined,
        loginUrl: '',
        login,
      }}
    >
      {showAuthError ? <ServerError /> : children}
    </TokenContext.Provider>
  );
};

const useSetRedirectPath = ({
  isAuthenticated,
  redirectUrl,
}: {
  isAuthenticated: boolean;
  redirectUrl?: string | null;
}): void => {
  const { pathname } = useLocation();

  useEffect((): void => {
    if (isAuthenticated) return;

    const redirectPath = Cookies.get(REDIRECT_PATH_COOKIE_NAME);
    if (!redirectPath) {
      Cookies.set(REDIRECT_PATH_COOKIE_NAME, pathname);
    }
  }, [pathname, redirectUrl, isAuthenticated]);
};

const useGoToRedirectPath = ({
  isAuthenticated,
  redirectUrl,
}: {
  isAuthenticated: boolean;
  redirectUrl?: string | null;
}): void => {
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated || !redirectUrl) return;

    const path = Cookies.get(REDIRECT_PATH_COOKIE_NAME);
    Cookies.remove(REDIRECT_PATH_COOKIE_NAME);

    const url = new URL(redirectUrl);
    const isSavedPathSameAsAuthRedirectPath = url.pathname === path;
    if (isSavedPathSameAsAuthRedirectPath) {
      history.push('/');
    } else {
      history.push(path ?? '/');
    }
  }, [history, redirectUrl, isAuthenticated]);
};

export const useRedirectPath = (): void => {
  const {
    user: { auth },
  } = useSelector(selectUser);
  const { isAuthenticated } = auth;
  const {
    auth: { client },
  } = useSelector(selectConfig);
  const { redirectUrl } = client;

  useSetRedirectPath({ isAuthenticated, redirectUrl });
  useGoToRedirectPath({ isAuthenticated, redirectUrl });
};
