import { GetMetricPayload } from './schemaTypes';

export enum MetricName {
  CURRENT_USAGE = 'CURRENT_USAGE',
  DEVICE_ID = 'DEVICE_ID',
  DEVICE_INFO = 'DEVICE_INFO',
  HISTORICAL_USAGE = 'HISTORICAL_USAGE',
  HISTORICAL_USAGE_BY_TRAFFIC_TYPE = 'HISTORICAL_USAGE_BY_TRAFFIC_TYPE',
  HISTORICAL_USAGE_BY_DEVICE = 'HISTORICAL_USAGE_BY_DEVICE',
  VDE_STATUS = 'VDE_STATUS',
  WIFI_DEVICE_INFO = 'WIFI_DEVICE_INFO',
  GET_MAC_ADDRESSES = 'GET_MAC_ADDRESSES',
}

export const PSMPartySummaryName = 'relnId';

export enum ProductInstanceTypes {
  Internet = 'FIXED_SATELLITE_INTERNET',
  WirelessInternet = 'FIXED_WIRELESS_INTERNET',
  Satellite = 'FIXED_SATELLITE_SERVICE',
  Voice = 'VOICE',
  Voip = 'VOIP',
  Easycare = 'EASY_CARE',
  SubscribeServices = 'SUBSCRIBER_SERVICES',
  ResidentialVoice = 'RESIDENTIAL_VOICE',
  Shield = 'SHIELD',
  Flex = 'DSL_INTERNET',
  ContentAddon = 'CONTENT_ADD_ON',
  ServiceContract = 'SERVICE_CONTRACT',
  IDU = 'SI_IDU',
}

export enum LifecycleStateType {
  Accepting = 'ACCEPTING',
  AcceptingError = 'ACCEPTING_ERROR',
  Accepted = 'ACCEPTED',
  Activating = 'ACTIVATING',
  ActivatingError = 'ACTIVATING_ERROR',
  Active = 'ACTIVE',
  Canceling = 'CANCELING',
  CancelingError = 'CANCELING_ERROR',
  Canceled = 'CANCELED',
  Deactivating = 'DEACTIVATING',
  DeactivatingError = 'DEACTIVATING_ERROR',
  Deactivated = 'DEACTIVATED',
  Processing = 'PROCESSING',
  ProcessingError = 'PROCESSING_ERROR',
  Processed = 'PROCESSED',
  Reactivating = 'REACTIVATING',
  ReactivatingError = 'REACTIVATING_ERROR',
  Suspending = 'SUSPENDING',
  SuspendingError = 'SUSPENDING_ERROR',
  Suspended = 'SUSPENDED',
}

export interface Address {
  addressLines: string[];
  municipality: string;
  region: string;
  postalCode?: string;
  countryCode: string;
}

export enum CommandNames {
  ASSESSMENT = 'ASSESSMENT',
  GET_EQUIPMENT_FILTERS = 'GET_EQUIPMENT_FILTERS',
  PROVISIONING_STATUS = 'PROVISIONING_STATUS',
  QOI_ASSESSMENT = 'QOI_ASSESSMENT',
  REBOOT = 'REBOOT',
  SPEED_TEST = 'SPEED_TEST',
  VDE_TOGGLE = 'VDE_TOGGLE',
}

export enum BillCycle {
  CURRENT_BILL_CYCLE = '0',
  PREVIOUS_BILL_CYCLE = '-1',
  SECOND_PREVIOUS_BILL_CYCLE = '-2',
}

export enum AssessmentType {
  CONTENT_ADDON = 'CONTENT_ADDON',
  QOI = 'QOI',
  EQUIPMENT = 'EQUIPMENT',
  NETWORK_CONNECTIVITY = 'NETWORK_CONNECTIVITY',
  PACKET_LOSS = 'PACKET_LOSS',
  PEER_CONGESTION = 'PEER_CONGESTION',
  PEER_NETWORK_CONNECTIVITY = 'PEER_NETWORK_CONNECTIVITY',
  PEER_PHY_CONNECTIVITY = 'PEER_PHY_CONNECTIVITY',
  PEER_SIGNAL_QUALITY = 'PEER_SIGNAL_QUALITY',
  PEER_WEB_ACCELERATION = 'PEER_WEB_ACCELERATION',
  PHY_CONNECTIVITY = 'PHY_CONNECTIVITY',
  PROVISIONING = 'PROVISIONING',
  PROVISIONING_DAP_STATUS = 'PROVISIONING_DAP_STATUS',
  PROVISIONING_SPEED = 'PROVISIONING_SPEED',
  SIGNAL_QUALITY = 'SIGNAL_QUALITY',
  THROUGHPUT = 'THROUGHPUT',
  WEB_ACCELERATION = 'WEB_ACCELERATION',
}

export interface ContentAddOnAssessment {
  $type: 'ContentAddOnAssessment';
  components: {
    $type: 'AssessmentComponent';
    explaination: string;
    name: string;
    result: string;
  }[];
  id: string;
  macAddress: string;
  result: string;
  supportingData?: {
    $type: 'SupportingAssessmentData';
    name: string;
    value: string;
  }[];
}

export interface DeviceInfoMetric extends GetMetricPayload {
  results: {
    isHTTPAccelerationRunning: boolean;
    isOnline: string;
    onlineStateDetail: string;
  };
}
