import { useTranslation } from 'react-i18next';

import { Icon, Txt } from '@vst/beam';
import { CheckCircle } from '@vst/beam-icons/icons';
import { CustomStandardModal } from '@mfe/shared/components';

import styles from './styles.module.scss';
import { ModalProps } from '../shared/types';

interface ConfirmationModalProps {
  retentionOfferName: string;
}

export const RetentionOfferConfirmationModal = ({
  isOpen,
  handleCancel,
  handleContinue,
  retentionOfferName,
}: ModalProps & ConfirmationModalProps) => {
  const { t } = useTranslation('Disconnect');

  return (
    <CustomStandardModal
      isOpen={isOpen}
      onClose={handleCancel}
      size={'medium'}
      header={''}
      actions={{
        primary: {
          label: `${t('retentionOffers.confirmationModal.primaryButton')}`,
          onClick: handleContinue,
        },
        secondary: {
          label: `${t('retentionOffers.confirmationModal.secondaryButton')}`,
          onClick: handleCancel,
        },
      }}
      surfaceProps={{ className: styles['retentionOfferConfirmationModal'] }}
    >
      <>
        <div className={styles['modalContentWrapper']}>
          <Txt variant="heading3">
            {t('retentionOffers.confirmationModal.header')}
          </Txt>
          <Txt color="info" variant="bodySmallRegular">
            {t('retentionOffers.confirmationModal.description')}
          </Txt>
        </div>
        <div className={styles['confirmationModal']}>
          <Icon name="check" size={30} icon={CheckCircle} color="green_500" />
          <Txt variant={'heading5'} style={{ textAlign: 'center' }}>
            {retentionOfferName}
          </Txt>
        </div>
      </>
    </CustomStandardModal>
  );
};
