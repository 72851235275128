import { useTranslation } from 'react-i18next';
import { Badge, Button, Txt } from '@vst/beam';

import { AddonIcon } from '@mfe/shared/components';

import { AddonsGridItemType } from './types';
import styles from './index.module.scss';

export const AddonsGridItem: React.FC<AddonsGridItemType> = (props) => {
  const {
    kind,
    title,
    badgeText,
    price,
    oldPrice,
    description,
    button,
    promotions,
    totalPromotionDuration,
    isGroupedProduct,
  } = props;

  const { t } = useTranslation('ShopAddons');

  const priceColor =
    !!promotions && !!promotions.length ? 'success' : 'regular';

  return (
    <div className={styles['gridItem']}>
      <AddonIcon kind={kind} className={styles['gridItem__image']} />

      <div className={styles['gridItem__header']}>
        <TitleWithBadge title={title} badgeText={badgeText} />
        <div className={styles['gridItem__description']}>
          <Txt variant="bodyLargeRegular">{description}</Txt>
        </div>
      </div>

      <div className={styles['gridItem__pricesAndPromotions']}>
        {isGroupedProduct && (
          <Txt variant="smallRegular" color="subtle">
            {t('startingAt')}
          </Txt>
        )}

        <div className={styles['gridItem__prices']}>
          {price !== undefined && (
            <Price price={price} priceColor={priceColor} />
          )}

          {!!totalPromotionDuration && (
            <Txt variant="smallRegular" color={priceColor}>
              {t('totalPromotionDuration', { totalPromotionDuration })}
            </Txt>
          )}
        </div>

        {!!promotions && !!promotions.length && (
          <div className={styles['gridItem__promotion']}>
            <Txt variant="smallRegular" color="subtle">
              {t('priceAfterPromotion', {
                oldPrice,
                totalPromotionDuration,
              })}
            </Txt>
          </div>
        )}
      </div>

      <div className={styles['gridItem__action']}>
        <Button
          variant="secondary"
          disabled={button.disabled}
          id="terms"
          fluid={true}
          onClick={button.onClick}
        >
          {button.text}
        </Button>
      </div>
    </div>
  );
};

const TitleWithBadge = (
  props: Pick<AddonsGridItemType, 'title' | 'badgeText'>
) => {
  const { title, badgeText } = props;

  return (
    <div className={styles['gridItem__title']}>
      <Txt variant="heading2" className={styles['gridItem__title-text']}>
        {title}
      </Txt>
      {badgeText && (
        <Badge
          size="small"
          showIcon={false}
          emphasis="light"
          state="infoPrimary"
          label={badgeText}
        />
      )}
    </div>
  );
};

const Price = (
  props: Required<Pick<AddonsGridItemType, 'price'>> & {
    priceColor: 'success' | 'regular';
    baselineGroupPrice?: string;
  }
) => {
  const { t } = useTranslation('ShopAddons');
  const { price, priceColor } = props;
  const splitPrice = price.toString().split('.');
  const integer = splitPrice[0];
  // Limit the amount of decimals displayed
  const decimals = (splitPrice[1] ?? '0').padEnd(2, '0').slice(0, 2);

  return (
    <div className={styles['gridItem__price']}>
      {price === 0 ? (
        <div className={styles['gridItem__price-wrap']}>
          <Txt variant="heading3" color={priceColor}>
            {t('freePrice')}
          </Txt>
        </div>
      ) : (
        <>
          <div className={styles['gridItem__price-wrap']}>
            <Txt variant="heading3" color={priceColor}>
              ${integer}
              <Txt
                component="span"
                variant="headerCapsLarge"
                className={styles['gridItem__price-decimals']}
              >
                {decimals}
              </Txt>
            </Txt>
          </div>
          <Txt variant="bodySmallBold" color={priceColor}>
            {t('monthUnit')}
          </Txt>
        </>
      )}
    </div>
  );
};
