import React from 'react';
import { Icon, Txt } from '@vst/beam';
import { Message } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import myversion from '@mfe/legacy/mv/myversion.json';
import { Locale, Platform } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectConfig } from '@mfe/shared/redux/config';
import { useDetectBrowser } from '@mfe/shared/util';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

import styles from './feedback-button.module.scss';
import {
  AnalyticsEventNames,
  Categories,
  DataLayerEventName,
  pushToAdobeDataLayer,
} from '@mfe/to-be-migrated/redux/analytics';

const useFeedbackLink = (): string => {
  const { t } = useTranslation('Footer');
  const { userInfo } = useSelector(selectUserInfo);
  const { platform } = useSelector(selectConfig);
  const { locale } = useSelector(selectLocale);

  const PlatformLocale = () => {
    if (locale.userLocale === Locale.ItIt) return 'eu_web';
    return platform === Platform.Web ? 'web' : 'app';
  };

  const browser = useDetectBrowser();
  const url = t('feedbackLink', {
    context: PlatformLocale(),
    accountNumber: userInfo.accountNumber,
    browser,
    version: myversion.version,
  });
  return url;
};

export const FeedbackButton = () => {
  const { t } = useTranslation('Footer');
  const feedback = useFeedbackLink();

  const handleClick = (): void => {
    pushToAdobeDataLayer({
      event: DataLayerEventName.LINK,
      event_label: AnalyticsEventNames.FeedbackButton,
      event_category: Categories.Feedback,
    });
  };

  return (
    <a
      href={feedback}
      rel="noreferrer"
      target="_blank"
      data-cy="footer-feedback"
      className={styles['feedbackButton']}
      onClick={handleClick}
    >
      <Icon icon={Message} size={16} />
      <Txt variant="bodySmallRegular" component="p">
        {t('feedback')}
      </Txt>
    </a>
  );
};
