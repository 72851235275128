import React from 'react';
import { Badge, Icon, Surface, Txt } from '@vst/beam';

import { GetCurrentAddOnsPayload } from '@mfe/shared/schema-types';
import { ADD_ON_STATE } from '@mfe/shared/util';

import { getBadgeForAddOn, getProps } from './utils';
import styles from './styles.module.scss';

type AddOnCardProps = {
  addon: GetCurrentAddOnsPayload;
  children: React.ReactNode;
};

export const AddOnCard: React.FC<AddOnCardProps> = (props) => {
  const { children, addon } = props;
  const { name, state } = addon;

  if (!name) return null;

  const addonProps = getProps(name);
  const { iconBackground, iconName, iconColor } = addonProps ?? {
    iconBackground: undefined,
    iconName: undefined,
    iconColor: 'inherit',
  };

  const pendingDeactivation = state.includes(ADD_ON_STATE.PENDING);
  const badgeState = getBadgeForAddOn(state);

  return (
    <div className={styles['add-on-card-container']} data-cy="addons-container">
      <Surface
        style={{ background: iconBackground }}
        className={styles['icon-container']}
      >
        {iconName && <Icon size={'24'} icon={iconName} color={iconColor} />}
      </Surface>
      <div className={styles['add-on-content']} data-cy="add-on-content">
        <div className={styles['title-and-badge']}>
          <Txt variant="bodyLargeBold">{name}</Txt>
          <Badge
            state={pendingDeactivation ? 'infoSecondary' : badgeState}
            label={state}
            showIcon={false}
          />
        </div>
        {children}
      </div>
    </div>
  );
};
