import * as React from 'react';
import { Txt, colors } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectCurrentUsage,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import { Collapsible } from '@mfe/shared/components';
import { Platform } from '@mfe/shared/schema-types';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

import { CurrentPlan, CurrentPlanBadge } from '../CurrentPlan/CurrentPlan';
import styles from './CollapsibleCurrentPlan.module.scss';

export const CollapsibleCurrentPlan = ({
  isHidden,
}: {
  isHidden?: boolean;
}) => {
  const { t } = useTranslation('NewChangePlan');

  const {
    data: { downloadSpeed },
  } = useSelector(selectDownloadSpeeds);

  const {
    data: { monthlyDataCap },
  } = useSelector(selectCurrentUsage);

  const {
    characteristics: { dataCap, name },
  } = useSelector(selectPlanCharacteristics);
  const isHibernation = name.toLowerCase().includes('hibernation');

  const { hideBroadbandLabels } = useSelector(selectChangePlan);
  const { platform, isPWA } = useSelector(selectConfig);

  const [isOpen, setIsOpen] = React.useState(false);

  const contentPaddingBottom =
    (platform !== Platform.Web || isPWA) && !hideBroadbandLabels
      ? '70px'
      : undefined;
  const hasScrollableContent = !hideBroadbandLabels && isOpen;

  React.useEffect(() => {
    if (hasScrollableContent) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.removeProperty('overflow-y');
    }
  }, [hasScrollableContent]);

  return (
    <Collapsible
      dataCy="collapsible-current-plan"
      hasBoxShadow={true}
      onToggle={(isDetailsOpen) => setIsOpen(isDetailsOpen)}
      classNames={{
        details: `${styles['current-plan-collapsible-details']} ${
          isHidden ? styles['hidden'] : ''
        }`,
        summary: styles['current-plan-collapsible-summary'],
      }}
      summary={
        <div className={styles['current-plan-summary-content']}>
          <div>
            {downloadSpeed && (
              <Txt component="span" variant="bodyLargeRegular" mr="4px">
                {t('plan.mobileHeader.downloadSpeed', { value: downloadSpeed })}
              </Txt>
            )}
            <DataCap
              dataCapGB={dataCap ?? monthlyDataCap.toString()}
              downloadSpeed={downloadSpeed}
            />
            {isHibernation && <Txt variant="bodyLargeRegular">{name}</Txt>}
          </div>
          <CurrentPlanBadge />
        </div>
      }
      content={
        <div
          data-scrollable={hasScrollableContent ? 'yes' : 'no'}
          className={styles['collapsible-content']}
          style={{ paddingBottom: contentPaddingBottom }}
        >
          <CurrentPlan isCollapsibleCurrentPlan={true} />
        </div>
      }
    />
  );
};

type Props = {
  dataCapGB: string;
  downloadSpeed: string;
};

const DataCap = ({ dataCapGB, downloadSpeed }: Props) => {
  const { t } = useTranslation('NewChangePlan');
  const hasDownloadSpeedAndDataCap = downloadSpeed && dataCapGB;

  if (!dataCapGB) return null;

  return (
    <Txt
      component="span"
      variant="bodyLargeRegular"
      style={
        hasDownloadSpeedAndDataCap
          ? {
              borderLeft: `1px solid ${colors['gray'][300]}`,
              marginLeft: '3px',
            }
          : undefined
      }
      pl="8px"
    >
      {t('plan.mobileHeader.updatedDataCap', {
        value: dataCapGB,
      })}
    </Txt>
  );
};
