import { useTranslation } from 'react-i18next';
import { EmptyState, InlineLink, Surface, Txt } from '@vst/beam';
import useNavigate from '@mfe/services/navigation';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';

import { Container } from '@mfe/shared/components';

const CustomerSupportTelLink = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Txt as="span" variant={'bodyLargeRegular'}>
      {t('error.description')}
      <InlineLink
        variant="primary"
        className={styles['customer-support-tel-link']}
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};

const VoiceEquipmentDescription = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Txt as="span" variant={'bodyLargeRegular'}>
      {t('voiceEquipmentError.descriptionOne')}
      <InlineLink
        variant="primary"
        className={styles['customer-support-tel-link']}
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
      {t('voiceEquipmentError.descriptionTwo')}
    </Txt>
  );
};

export const OrderError = ({
  title,
  handlePrimaryAction,
}: {
  title: string;
  handlePrimaryAction?: () => void;
}) => {
  const { t } = useTranslation('ShopAddons');
  const { goBack } = useNavigate();
  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  const description =
    title === t('error.title') ? (
      <CustomerSupportTelLink />
    ) : (
      <VoiceEquipmentDescription />
    );

  return (
    <Container>
      <Surface
        className={`${styles['container']} ${styles['error']}`}
        data-cy="order-confirmation-error"
        radius="16px"
      >
        <EmptyState
          py={'40px'}
          px={isMobile ? '24px' : '40px'}
          icon={Alert}
          title={title}
          description={description}
          actions={{
            primary: {
              label: t('error.button'),
              onClick: () => {
                if (handlePrimaryAction) {
                  handlePrimaryAction();
                }
                goBack();
              },
              variant: 'primary',
            },
          }}
        />
      </Surface>
    </Container>
  );
};
