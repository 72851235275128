import { Alert } from '@vst/beam-icons/illustrative-icons';
import { Icon, InlineLink, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { CustomStandardModal } from '@mfe/shared/components';
import styles from './styles.module.scss';

const CustomerSupportTelLink = ({ ns }: { ns: string }) => {
  const { t } = useTranslation(ns);

  return (
    <Txt as="span" variant={'bodyLargeRegular'} mt={'12px'}>
      {t('error.customerSupportMessage')}
      <InlineLink
        ml="4px"
        variant="primary"
        className={styles['customer-support-tel-link']}
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};

type ErrorAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  ns: string;
};

export const ErrorAddressModal = ({
  isOpen,
  onClose,
  ns,
}: ErrorAddressModalProps) => {
  const { t } = useTranslation(ns);

  return (
    <div data-cy={'e911-address-error-modal'}>
      <CustomStandardModal
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        header={undefined}
        actions={{
          primary: {
            label: t('error.button.primary'),
            onClick: onClose,
            minWidth: '200px',
          },
        }}
        surfaceProps={{ className: styles['modal-content'] }}
      >
        <div className={styles['modal-body']}>
          <div data-cy="Alert-Icon">
            <Icon icon={Alert} size={100} color={'alert_400'} mb={'48px'} />
          </div>
          <Txt variant="heading4" color={'subtle'} mb={'24px'}>
            {t('error.description')}
          </Txt>
          <Txt variant={'bodyLargeRegular'} color={'regular'}>
            <CustomerSupportTelLink ns={ns} />
          </Txt>
        </div>
      </CustomStandardModal>
    </div>
  );
};
