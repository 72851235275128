import { createAction, createSlice } from '@reduxjs/toolkit';
import { Platform, OAuthClient } from '@mfe/shared/schema-types';

export enum Issuer {
  MV = 'MyViasat',
  Salesforce = 'Salesforce',
}

export interface RegistrationConfig {
  client: OAuthClient;
  issuer: string;
}

export interface AuthConfig {
  baseUrl: string;
  client: OAuthClient;
  myViasatUrl?: string;
  authMethod: 'web' | 'mobile';
}

export const initialClient = {
  clientId: '',
  redirectUrl: '',
};

interface LaunchDarklyFlags {
  allowAnalytics: boolean;
  showChangePlan: boolean;
  showChangePlanSMB: boolean;
  showChat: boolean;
  showDevicesGraph: boolean;
  showDonutChart: boolean;
  showPaypalPaymentOption: boolean;
  inMaintenance: boolean | null;
  showACPBanner: boolean;
  showOverviewRedesign: boolean;
  showBroadbandLabels: boolean;
  showNetworkPage: boolean;
  showUnleashedUsage: boolean;
  getMockTier: boolean;
  setACHasDefault: boolean;
  showAddonsShop: boolean;
  disableResidentialAddons: Array<string> | null;
  disableSmbAddons: Array<string> | null;
  showRemoveAddonFlow: boolean;
  showPreAuthDisclaimer: boolean;
  showGroupedEasyCare: boolean;
  showClickToDisconnect: boolean;
  showRetentionOffers: boolean;
}

export interface ConfigState extends LaunchDarklyFlags {
  loadingConfig: boolean;
  analyticsUrl: string;
  aemBaseUrl: string;
  aemContentBaseUrl: string;
  auth: AuthConfig;
  env: string;
  isProfileReadOnly: boolean;
  isPWA: boolean;
  issuer: Issuer;
  mfeEnv: string;
  platform: Platform;
  reg: RegistrationConfig;
  version: string;
  vppUrl: string;
}

export const initialConfigState: ConfigState = {
  loadingConfig: false,
  auth: {
    baseUrl: '',
    client: initialClient,
    authMethod: 'web',
    myViasatUrl: '',
  },
  allowAnalytics: false,
  aemBaseUrl: '',
  aemContentBaseUrl: '',
  analyticsUrl: '',
  env: '',
  isProfileReadOnly: false,
  isPWA: false,
  issuer: Issuer.MV,
  mfeEnv: '',
  platform: Platform.Web,
  reg: { client: initialClient, issuer: '' },
  showACPBanner: false,
  showChangePlan: true,
  showChangePlanSMB: false,
  showChat: false,
  showDevicesGraph: false,
  showDonutChart: false,
  showPaypalPaymentOption: true,
  version: '4.4.3',
  vppUrl: '',
  inMaintenance: null,
  showOverviewRedesign: true,
  showBroadbandLabels: false,
  showNetworkPage: false,
  showUnleashedUsage: false,
  getMockTier: false,
  setACHasDefault: false,
  showAddonsShop: false,
  disableResidentialAddons: null,
  disableSmbAddons: null,
  showRemoveAddonFlow: false,
  showPreAuthDisclaimer: false,
  showGroupedEasyCare: false,
  showClickToDisconnect: false,
  showRetentionOffers: false,
};

export const configSlice = createSlice({
  name: 'config',
  initialState: initialConfigState,
  reducers: {
    fetchConfig: (state) => {
      state.loadingConfig = true;
    },

    setIssuer: (state, { payload }: { payload: Issuer }) => {
      state.issuer = payload;
    },
    setConfig: (state, { payload }: { payload: Partial<ConfigState> }) => {
      return { ...state, ...payload };
    },
    setEnvConfig: (state, { payload }: { payload: string }) => {
      state.env = payload;
    },
  },
});

export const { setConfig, setIssuer, fetchConfig } = configSlice.actions;

export const selectConfig = (state: { config: ConfigState }) => state.config;
export const getMaintenanceStatus = createAction('config/getMaintenanceStatus');
