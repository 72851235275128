import { ScreenEntry } from './utils';
import {
  BillingSwitch,
  PlanSwitch,
  HelpSwitch,
  NetworkSwitch,
  ShopAddonsSwitch,
} from '../Navigator/NestedSwitch';
import UpdateBillCycle from '../views/UpdateBillCycle';
import {
  StackLink,
  PayBillLink,
  HelpLink,
  PlanLink,
  WebRoutes,
  WebHome,
  NetworkLink,
  ShopAddonsLink,
} from '@mfe/legacy/mv/utils/Navigation';
import { Profile } from '@mfe/legacy/mv/views/Profile';
import { WebHome as Home } from '../views/WebTabs';
import WrapWithMobileStackNav from '../services/WrapWithMobileStackNav';
import Stream from '@mfe/legacy/mv/views/Stream';
import ACP from '@mfe/legacy/mv/views/ACP';
import NewScheduleInstallation from '../views/NewScheduleInstallation';

import InvoiceLoading from '@mfe/legacy/mv/views/PayBill/Invoice';
import LiveChat from '../views/ContactUs';
import { Disconnect } from '../views/Disconnect';

const MobileProfile = () => <Profile />;

const MobileLiveChat = () => (
  <WrapWithMobileStackNav navTranslationKey="ChatHeader">
    <LiveChat />
  </WrapWithMobileStackNav>
);

type AppRouter = ScreenEntry[];

export const defaultAppRoutes: AppRouter = [
  {
    label: WebRoutes.Home,
    path: `/${WebRoutes.Main}`,
    screen: Home,
    exact: true,
  },
  {
    label: PayBillLink.PayBill,
    path: `/${PayBillLink.PayBill}`,
    screen: BillingSwitch,
  },
  {
    label: PlanLink.Plan,
    path: `/${PlanLink.Plan}`,
    screen: PlanSwitch,
  },
  {
    label: NetworkLink.Network,
    path: `/${NetworkLink.Network}`,
    screen: NetworkSwitch,
  },
  {
    label: HelpLink.Main,
    path: `/${HelpLink.Main}`,
    screen: HelpSwitch,
  },
  {
    label: StackLink.Profile,
    path: `/${StackLink.Profile}`,
    screen: MobileProfile,
  },
  {
    label: WebHome.ACP,
    path: `/${StackLink.ACP}`,
    screen: ACP,
  },
  {
    label: WebHome.Stream,
    path: `/${StackLink.Stream}`,
    screen: Stream,
  },
  {
    label: HelpLink.AgentChat,
    path: `/${HelpLink.AgentChat}`,
    screen: MobileLiveChat,
  },
  {
    label: PayBillLink.UpdateBillCycle,
    path: `/${PayBillLink.UpdateBillCycle}`,
    screen: UpdateBillCycle,
  },
  {
    label: PayBillLink.Invoice,
    path: `/${PayBillLink.Invoice}/:invoice`,
    screen: InvoiceLoading,
  },
  {
    label: StackLink.ScheduleInstallation,
    path: `/${StackLink.ScheduleInstallation}`,
    screen: NewScheduleInstallation,
  },
  {
    label: ShopAddonsLink.ShopAddons,
    path: `/${ShopAddonsLink.ShopAddons}`,
    screen: ShopAddonsSwitch,
  },
];

export const fixedWirelessAppRoutes: AppRouter = [
  {
    label: WebRoutes.Home,
    path: `/${WebRoutes.Main}`,
    screen: Home,
    exact: true,
  },
  {
    label: PayBillLink.PayBill,
    path: `/${PayBillLink.PayBill}`,
    screen: BillingSwitch,
  },
  {
    label: StackLink.Profile,
    path: `/${StackLink.Profile}`,
    screen: MobileProfile,
  },
  {
    label: PayBillLink.UpdateBillCycle,
    path: `/${PayBillLink.UpdateBillCycle}`,
    screen: UpdateBillCycle,
  },
  {
    label: PayBillLink.Invoice,
    path: `/${PayBillLink.Invoice}/:invoice`,
    screen: InvoiceLoading,
  },
];

export const showDisconnectRoute = () => {
  defaultAppRoutes.push({
    label: StackLink.Disconnect,
    path: `/${StackLink.Disconnect}`,
    screen: Disconnect,
  });
  fixedWirelessAppRoutes.push({
    label: StackLink.Disconnect,
    path: `/${StackLink.Disconnect}`,
    screen: Disconnect,
  })
}
