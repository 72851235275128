import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import {
  selectChangePlan,
  addPlanToCart,
} from '@mfe/to-be-migrated/redux/changePlan';
import { selectConfig } from '@mfe/shared/redux/config';
import { GetPlanOffersPayload } from '@mfe/shared/schema-types';
import {
  createAemUrl,
  AEMContentProvider,
  AemFeature,
} from '@mfe/shared/components';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { showTitleAddons } from '@mfe/shared/util';

import { PlanCard } from '../PlanCard';
import { TitleAddOns } from '../../shared';
import { PlanCardBody } from '../PlanCard/PlanCardBody';
import { PlanCardPrice } from '../PlanCard/PlanCardPrice';

import styles from './AvailablePlans.module.scss';
import {
  AnalyticsEventNames,
  Categories,
  DataLayerEventName,
  pushToAdobeDataLayer,
} from '@mfe/to-be-migrated/redux/analytics';

export const AvailablePlans = () => {
  const dispatch = useDispatch();

  const { planOffersData, loading } = useSelector(selectChangePlan);

  if (!planOffersData && !loading) {
    return null;
  }

  return (
    <Surface
      variant="secondary"
      className={styles['gridSurface']}
      data-cy="available-plans"
    >
      <Container
        fluid
        className={styles['gridContainer']}
        style={{
          justifyItems: 'center',
          maxWidth: 828,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          planOffersData?.map((plan: GetPlanOffersPayload, i: number) => {
            const handleClick = () => {
              dispatch(addPlanToCart({ newProductTypeId: plan.id }));
              pushToAdobeDataLayer({
                event: DataLayerEventName.PAGE_VIEW,
                event_name: DataLayerEventName.PAGE_VIEW,
                event_label: `${AnalyticsEventNames.ChangePlanSelected} | Selected plan: ${plan.characteristics.offerName}`,
                event_category: Categories.ChangePlan,
              });
            };

            return (
              <AvailablePlan
                plan={plan}
                broadbandLabel={<BroadbandLabel plan={plan} />}
                handleClick={handleClick}
              />
            );
          })
        )}
      </Container>
    </Surface>
  );
};

const BroadbandLabel = ({ plan }: { plan: GetPlanOffersPayload }) => {
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const { aemBaseUrl } = useSelector(selectConfig);

  return (
    <AEMContentProvider
      src={createAemUrl({
        baseUrl: aemBaseUrl,
        locale: userLocale,
        feature: AemFeature.PLAN_LABEL,
        accountType,
        additionalSelectors: ['pidi'],
        suffixes: {
          upi: plan.characteristics.upi ?? null,
          serviceAreaId: plan.characteristics.serviceAreaId ?? null,
          offerId: plan.offerId,
        },
      })}
      id={`plan-label-${plan.characteristics.offerName}`}
      style={{ textAlign: 'left' }}
    />
  );
};

export const AvailablePlan = ({
  handleClick,
  broadbandLabel,
  plan,
}: {
  handleClick: () => void;
  broadbandLabel?: ReactNode | null;
  plan: GetPlanOffersPayload;
}) => {
  const { t } = useTranslation('NewChangePlan');

  const { showBroadbandLabels } = useSelector(selectConfig);
  const disclosure = showBroadbandLabels ? undefined : plan.disclosure;

  const showTitleAddOns = showTitleAddons(
    plan.characteristics.family,
    plan.characteristics.titleAddOns
  );

  return (
    <PlanCard
      variant="default"
      name={plan.characteristics.offerName}
      broadbandLabel={broadbandLabel}
    >
      <PlanCardBody
        characteristics={plan.characteristics}
        disclosure={disclosure}
      />
      {showTitleAddOns && (
        <TitleAddOns characteristic={plan.characteristics.titleAddOns || ''} />
      )}
      <div>
        <Button
          buttonSize="large"
          fluid={true}
          variant="secondary"
          onClick={handleClick}
          mb="24px"
        >
          {t('plan.choosePlan')}
        </Button>
        <PlanCardPrice
          price={plan?.price?.toString() ?? ''}
          currency={plan.priceCurrency ?? undefined}
        />
      </div>
    </PlanCard>
  );
};

export const Loading = () => {
  return (
    <>
      <PlanCard name="-">
        <div className={styles['loadingCharacteristics']} />
        <div className={styles['loadingButton']} />
        <div className={styles['loadingPrice']} />
        <div className={styles['loadingLabel']} />
      </PlanCard>
      <PlanCard name="-">
        <div className={styles['loadingCharacteristics']} />
        <div className={styles['loadingButton']} />
        <div className={styles['loadingPrice']} />
        <div className={styles['loadingLabel']} />
      </PlanCard>
    </>
  );
};
