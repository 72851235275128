import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Router } from '@vst/beam-icons/icons';
import { InlineLink, SectionAlert, Txt } from '@vst/beam';

import { selectingPendingInstallation } from '@mfe/to-be-migrated/redux/pendingInstallation';

import styles from './Alert.module.scss';

interface UnscheduledAlertProps {
  handleRedirect: () => void;
}

export const UnscheduledAlert = ({ handleRedirect }: UnscheduledAlertProps) => {
  const { t } = useTranslation('Profile');

  const { newOfferName } = useSelector(selectingPendingInstallation);

  return (
    <SectionAlert variant="warning" icon={Router}>
      <div className={styles['alert-content']}>
        <Txt
          data-cy="text"
          component="span"
          variant="smallRegular"
          color="regular"
        >
          {t('unscheduledAlert.placedOrder', { newOfferName })}
        </Txt>
        <Txt
          data-cy="highlight"
          component="span"
          variant="smallBold"
          color="regular"
        >
          {t('unscheduledAlert.notScheduled')}
        </Txt>
        <InlineLink
          data-cy="cta"
          variant="primary"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect();
          }}
        >
          {t('unscheduledAlert.cta')}
        </InlineLink>
      </div>
    </SectionAlert>
  );
};
