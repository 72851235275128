import { Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { CustomStandardModal } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';

type SuggestedAddressModalProps = {
  isOpen: boolean;
  suggestedAddress: string[];
  handleClose: () => void;
  handleSave: () => void;
  ns: string;
};

export const SuggestedAddressModal = ({
  isOpen,
  handleClose,
  suggestedAddress,
  handleSave,
  ns,
}: SuggestedAddressModalProps) => {
  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const { t } = useTranslation(ns);

  const style = isMobile
    ? { display: 'flex', order: 1, flexBasis: 'unset' }
    : { flexBasis: 'unset' };

  return (
    <div>
      <CustomStandardModal
        isOpen={isOpen}
        onClose={handleClose}
        size="medium"
        header={t('title')}
        actions={{
          primary: {
            label: `${t('suggested.button.primary')}`,
            onClick: handleSave,
            minWidth: '200px',
            style,
          },
          secondary: {
            label: `${t('suggested.button.secondary')}`,
            onClick: handleClose,
            minWidth: '200px',
            style,
          },
        }}
        surfaceProps={{ className: styles['modal-content'] }}
      >
        <>
          <Txt variant="bodySmallRegular" color="regular" mb="24px">
            {t('suggested.description')}
          </Txt>
          <Txt variant="bodySmallBold" color="subtle" mb="24px">
            {t('suggested.prompt')}
          </Txt>
          <Surface
            variant="secondary"
            px="24px"
            py="24px"
            radius="8px"
            style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
          >
            {suggestedAddress?.map((line, index) => (
              <Txt color="subtle" variant="paragraphRegular" component="span">
                {line}
              </Txt>
            ))}
          </Surface>
        </>
      </CustomStandardModal>
    </div>
  );
};
