import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

import {
  NotificationLevel,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';

import {
  getCharacteristics,
  getDownloadSpeeds,
  getPrice,
  getUsage,
  refetchUsage,
  selectCurrentUsage,
  selectPlanCharacteristics,
  selectPlanInfo,
  setCharacteristics,
  setCharacteristicsError,
  setDownloadSpeeds,
  setDownloadSpeedsError,
  setLoadingTier,
  setPrice,
  setPriceError,
  setTier,
  setTierError,
  setUsage,
  setUsageError,
  setUsageLoading,
} from './planSlice';
import {
  GET_CURRENT_USAGE,
  GET_DOWNLOAD_SPEEDS,
  GET_PLAN_CHARACTERISTICS,
  GET_PLAN_PRICE,
  GET_USAGE_TIER,
} from './query';
import { waitForToken, waitForUserInfo } from '../utils/utilsSagas';
import { Alert, setAlerts } from '../alerts';
import { selectConfig } from '@mfe/shared/redux/config';
import { hasUnlimitedAverage } from '@mfe/shared/util';
import { selectUser } from '../auth';
import { selectLocale } from '../locale';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

function* fetchPlanCharacteristics() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PLAN_CHARACTERISTICS,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setCharacteristicsError(runtimeError ?? errors));
    return;
  }

  if (data?.getPlanCharacteristics) {
    yield put(setCharacteristics(data.getPlanCharacteristics));
  }
}

export function* fetchPlanPrice() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PLAN_PRICE,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setPriceError(runtimeError ?? errors));
    return;
  }

  if (data?.getCurrentPlanPrice) {
    yield put(setPrice(data.getCurrentPlanPrice.price));
  }
}

function* fetchPlanUsage(refetchData = false) {
  yield call(waitForToken);

  const {
    user: { productKind, productInstanceStatus },
  } = yield select(selectUser);

  if (
    productKind === ProductInstanceTypes.WirelessInternet ||
    productInstanceStatus === ProductInstanceStatus.Preinstall
  ) {
    yield put(setUsageLoading(false));
    return;
  }

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_CURRENT_USAGE,
    fetchPolicy: refetchData ? 'network-only' : 'cache-first',
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setUsageError(runtimeError ?? errors));
    return;
  }

  if (data?.getCurrentUsage) {
    yield put(setUsage(data.getCurrentUsage));
  }
}

export function* refetchUsageData() {
  yield call(fetchPlanUsage, true);
}

export function* fetchDownloadSpeeds() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_DOWNLOAD_SPEEDS,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setDownloadSpeedsError(runtimeError ?? errors));
    return;
  }

  if (data?.getDownloadSpeeds) {
    yield put(setDownloadSpeeds(data.getDownloadSpeeds));
  }
}

export const shouldMockTierDataForUser = (
  user: { accountNumber: string },
  env: string,
  getMockTier: boolean
) => {
  const isMockedAccount = Object.keys(TIER_ACCOUNT_NUMBERS).includes(
    user.accountNumber
  );
  return env !== 'PROD' && getMockTier && isMockedAccount;
};

const TIER_ACCOUNT_NUMBERS: Record<string, number> = {
  '5000357023': 0,
  '5000376617': 1,
  '5000323909': 2,
};

const getMockUsageTier = (accountNumber: string) => {
  return TIER_ACCOUNT_NUMBERS[accountNumber];
};

export function* fetchUsageTier() {
  yield put(setLoadingTier());
  yield call(waitForToken);
  yield call(waitForUserInfo);

  const {
    data: {
      characteristics: { isUnleashed, usageMeter },
    },
  } = yield select(selectPlanInfo);
  const { user } = yield select(selectUser);

  const { showUnleashedUsage, getMockTier, env } = yield select(selectConfig);
  const { accountNumber, productInstanceStatus } = user;

  const hasUnlimitedAverageMeter =
    showUnleashedUsage && hasUnlimitedAverage(usageMeter);

  const hasUnleashedPlan = hasUnlimitedAverageMeter || isUnleashed;
  const shouldShowUnleashed = showUnleashedUsage && hasUnleashedPlan;

  if (!shouldShowUnleashed) return;

  if (productInstanceStatus !== ProductInstanceStatus.Active) {
    yield put(setTierError('The FSI Product Instance is not Active'));
    return;
  }

  if (shouldMockTierDataForUser(user, env, getMockTier)) {
    yield put(setTier(getMockUsageTier(accountNumber)));
    return;
  }

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_USAGE_TIER,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setTierError(runtimeError ?? errors));

    return;
  }

  const tier = data?.getUsageTier?.tier;

  if (typeof tier === 'number' && !isNaN(tier)) {
    yield put(setTier(tier));
  } else {
    yield put(setTierError('Invalid tier'));
  }
}

const getAlertLevel: (receivedAlert: { type?: string }) => NotificationLevel = (
  receivedAlert
) => {
  const level = receivedAlert.type?.toLowerCase();
  if (
    level &&
    Object.values(NotificationLevel).includes(level as NotificationLevel)
  ) {
    return level as NotificationLevel;
  }
  return NotificationLevel.Info;
};

function* fetchTierAlerts(data: any): Generator<unknown, void, any> {
  const tier = data.payload;
  const { aemContentBaseUrl, showUnleashedUsage } = yield select(selectConfig);

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const [languageCode] = userLocale.split('_');
  const MODEL = 'alert';
  const TAG = 'my-alert';
  const AEMURL = `${aemContentBaseUrl}/${MODEL}/${languageCode}/${TAG}.json`;

  if (!showUnleashedUsage) {
    return;
  }

  if (tier === 2) {
    const response = yield call(fetch, AEMURL);
    const json = yield call([response, 'json']);

    if (json.success) {
      const alerts = Object.keys(json.data.Alerts)
        .filter((key) => key === 'RedTier')
        .map((key) => {
          const receivedAlert = json.data.Alerts[key];
          const alert: Alert = {
            title: receivedAlert.title,
            customDescription: receivedAlert.description,
            level: getAlertLevel(receivedAlert),
            type: 'global',
          };

          if (receivedAlert.ctaLink && receivedAlert.ctaButtonText) {
            alert.button = {
              url: receivedAlert.ctaLink,
              label: receivedAlert.ctaButtonText,
              openInSameTab: true,
            };
          }

          if (receivedAlert.learnMoreLink && receivedAlert.learnMoreText) {
            alert.link = {
              url: receivedAlert.learnMoreLink,
              label: receivedAlert.learnMoreText,
              openInSameTab: true,
            };
          }

          return alert;
        });

      yield put(setAlerts(alerts));
    }
  }
}

export function* waitForPlanCharacteristics() {
  const { loading } = yield select(selectPlanCharacteristics);

  if (loading) {
    yield take(setCharacteristics.type);
  }
}

export function* waitForUsage() {
  const { loading } = yield select(selectCurrentUsage);

  if (loading) {
    yield race({
      setUsageAction: take(setUsage.type),
      setUsageErrorAction: take(setUsageError.type),
    });
  }
}

export function* watchPlan() {
  yield all([
    takeLatest(getCharacteristics.type, fetchPlanCharacteristics),
    takeLatest(setCharacteristics.type, fetchUsageTier),
    takeLatest(getPrice.type, fetchPlanPrice),
    takeLatest(setTier.type, fetchTierAlerts),
    takeLatest(getUsage.type, fetchPlanUsage),
    takeLatest(getDownloadSpeeds.type, fetchDownloadSpeeds),
    takeLatest(refetchUsage.type, refetchUsageData),
  ]);
}
