import { gql } from '@apollo/client';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import {
  Query,
  Mutation,
  ContactMethodLabels,
  ResidentialProfileInfo,
  AccountType,
  SmbProfileInfo,
  Role,
  PhoneGroup,
  UpdateAccountInfoSmbInput,
  UpdateAccountInfoInput,
} from '@mfe/shared/schema-types';

import { selectUserInfo } from '../userInfo';
import {
  setUserInfo,
  setUpdateFailed,
  setUpdateSuccess,
  refetchUserInfo as refetchUserInfoAction,
  updateContactInfo as updateContactInfoAction,
  updateContactInfoSMB as updateContactInfoSMBAction,
} from './userInfoSlice';
import { scrollToTop } from '@mfe/shared/redux/utils';
import {
  graphqlQuery,
  graphqlQueryWithErrors,
  graphqlMutation,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import { setUser } from '../auth';
import { AnalyticsAction, Categories, structuredEvent } from '../analytics';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import {
  setPendingInstallationError,
  setPendingInstallation,
  getPendingInstallation,
} from '../pendingInstallation';
import { waitForToken } from '../utils/utilsSagas';

const UPDATE_CONTACT_INFO = gql`
  mutation updateContactInfo($input: UpdateAccountInfoInput!) {
    updateAccountInfo(input: $input) {
      email
      phone {
        primary {
          phoneNumber
          labels
        }
        mobile
        secondary {
          phoneNumber
          labels
        }
      }
      address {
        billing {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
        shipping {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
      }
    }
  }
`;

const UPDATE_CONTACT_INFO_SMB = gql`
  mutation updateContactInfoSMB($input: UpdateAccountInfoSMBInput!) {
    updateAccountInfoSMB(input: $input) {
      name
      email
      phone {
        primary {
          phoneNumber
          labels
        }
        mobile
        secondary {
          phoneNumber
          labels
        }
      }
      address {
        billing {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
        shipping {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
      }
    }
  }
`;

const GET_PROFILE_INFO = gql`
  query getProfileInfo($refetchData: Boolean) {
    getProfileInfo(refetchData: $refetchData) {
      accountType
      username
      partyId
      accountNumber
      isPreInstall
      address {
        billing {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
        shipping {
          addressLines
          countryCode
          municipality
          postalCode
          region
        }
      }
      ... on ResidentialProfileInfo {
        phone {
          mobile
          secondary {
            phoneNumber
            labels
          }
          primary {
            phoneNumber
            labels
          }
        }
        email
        fullName
        firstName
        lastName
        isACPEligible
      }

      ... on SMBProfileInfo {
        businessName
        accountHolder {
          partyId
          phone {
            mobile
            secondary {
              phoneNumber
              labels
            }
            primary {
              phoneNumber
              labels
            }
          }
          email
          fullName
          firstName
          lastName
        }
        siteContact {
          partyId
          phone {
            mobile
            secondary {
              phoneNumber
              labels
            }
            primary {
              phoneNumber
              labels
            }
          }
          email
          fullName
          firstName
          lastName
        }
        billingContact {
          partyId
          phone {
            mobile
            secondary {
              phoneNumber
              labels
            }
            primary {
              phoneNumber
              labels
            }
          }
          email
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;

const buildDisplayContext = (issuer: Issuer) => {
  return {
    schema: 'display_context',
    data: {
      display_zone: 'profile_page',
      display_platform: issuer === Issuer.MV ? 'myviasat' : 'salesforce',
      display_location: '',
    },
  };
};

// Unused but may be needed again for re-enabling Adobe Analytics
const buildProfileContext = (labels: ContactMethodLabels[], field: string) => {
  return {
    schema: 'profile_context',
    data: {
      ira_label: labels[0],
      profile_field: field,
    },
  };
};

// Unused but may be needed again for re-enabling Adobe Analytics
const getField = (
  labels: ContactMethodLabels[],
  email?: string,
  phone?: string,
  address?: any
) => {
  const label = labels[0];
  if (email) {
    return 'email';
  } else if (address) {
    return label === ContactMethodLabels.Invoice
      ? 'billing_address'
      : 'shipping_address';
  } else if (phone) {
    return label === ContactMethodLabels.MobileNumber
      ? 'mobile_phone_number'
      : 'phone_number';
  }

  return 'NA';
};

export function* updateContactInfoSMB(action: {
  type: string;
  payload: UpdateAccountInfoSmbInput;
}) {
  const {
    addresses,
    phones,
    deleteSecondaryPhone,
    email,
    role,
    shouldUpdateLoginUsername,
  } = action.payload;

  const { issuer }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );

  const {
    userInfo: { username, accountHolder },
  }: ReturnType<typeof selectUserInfo> = yield select(selectUserInfo);

  const oldEmail = accountHolder.email;

  try {
    const updateResponse: Mutation = yield call(graphqlMutation, {
      mutation: UPDATE_CONTACT_INFO_SMB,
      variables: {
        input: {
          phones,
          addresses,
          email,
          role,
          deleteSecondaryPhone,
          shouldUpdateLoginUsername,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (updateResponse?.updateAccountInfoSMB) {
      const {
        phone,
        address,
        email: updatedEmail,
      } = updateResponse.updateAccountInfoSMB;

      yield put(
        structuredEvent({
          category: Categories.Profile,
          action: AnalyticsAction.EDIT_SUCCESSFUL,
          params: {
            context: [buildDisplayContext(issuer)],
          },
        })
      );

      yield put(
        setUserInfo({
          accountHolder:
            role === Role.AccountHolder
              ? {
                  ...accountHolder,
                  phone: phone,
                  email: updatedEmail,
                }
              : undefined,
          address: role === Role.Customer ? address : undefined,
          username: oldEmail === username ? updatedEmail : username,
        })
      );
      yield put(setUpdateSuccess());
      yield put(scrollToTop());
    }
  } catch {
    yield put(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.EDIT_FAILED,
        params: {
          property: 'Failed to update Profile info.',
          context: [buildDisplayContext(issuer)],
        },
      })
    );

    yield put(setUpdateFailed());
    yield put(refetchUserInfoAction());
  }
}

export function* updateContactInfo(action: {
  type: string;
  payload: UpdateAccountInfoInput;
}) {
  const {
    addresses,
    phones,
    deleteSecondaryPhone,
    email,
    shouldUpdateLoginUsername,
  } = action.payload;

  const { issuer }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );

  const {
    userInfo: { email: oldEmail, username },
  }: ReturnType<typeof selectUserInfo> = yield select(selectUserInfo);

  try {
    const updateResponse: Mutation = yield call(graphqlMutation, {
      mutation: UPDATE_CONTACT_INFO,
      variables: {
        input: {
          phones,
          addresses,
          email,
          deleteSecondaryPhone,
          shouldUpdateLoginUsername,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (updateResponse?.updateAccountInfo) {
      const {
        phone,
        address,
        email: updatedEmail,
      } = updateResponse.updateAccountInfo;

      yield put(
        structuredEvent({
          category: Categories.Profile,
          action: AnalyticsAction.EDIT_SUCCESSFUL,
          params: {
            context: [buildDisplayContext(issuer)],
          },
        })
      );

      yield put(
        setUserInfo({
          phone: phone,
          address: address,
          email: updatedEmail,
          username: oldEmail === username ? updatedEmail : username,
        })
      );
      yield put(setUpdateSuccess());
    }
  } catch {
    yield put(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.EDIT_FAILED,
        params: {
          property: 'Failed to update Profile info.',
          context: [buildDisplayContext(issuer)],
        },
      })
    );

    yield put(setUpdateFailed());
    yield put(scrollToTop());
    yield put(refetchUserInfoAction());
  }
}

export function* fetchUserInfo(payload?: { refetchData: boolean }) {
  const data: Query = yield call(graphqlQuery, {
    query: GET_PROFILE_INFO,
    variables: {
      refetchData: payload?.refetchData ?? true,
    },
    fetchPolicy: payload?.refetchData ? 'no-cache' : 'cache-first',
  });

  const getProfileInfo = data?.getProfileInfo;

  const isSMBAccount = data.getProfileInfo?.accountType === AccountType.Smb;

  if (isSMBAccount) {
    const result = getProfileInfo as SmbProfileInfo;

    yield put(
      setUserInfo({
        username: result?.username ?? undefined,
        partyId: result?.partyId ?? undefined,
        accountNumber: result?.accountNumber ?? undefined,
        accountHolder: {
          partyId: result?.accountHolder?.partyId ?? '',
          fullName: result?.accountHolder?.fullName ?? '',
          firstName: result?.accountHolder?.firstName ?? '',
          lastName: result?.accountHolder?.lastName ?? '',
          email: result?.accountHolder?.email ?? '',
          phone: result?.accountHolder?.phone ?? ({} as PhoneGroup),
        },
        address: result?.address ?? undefined,
        isPreInstall: result?.isPreInstall ?? undefined,
        accountType: result?.accountType ?? undefined,
        businessName: result?.businessName ?? undefined,
      })
    );
    return;
  }

  const result = getProfileInfo as ResidentialProfileInfo;

  yield put(
    setUserInfo({
      username: result?.username ?? undefined,
      partyId: result?.partyId ?? undefined,
      accountNumber: result?.accountNumber ?? undefined,
      fullName: result?.fullName ?? undefined,
      firstName: result?.firstName ?? undefined,
      lastName: result?.lastName ?? undefined,
      email: result?.email ?? undefined,
      phone: result?.phone ?? undefined,
      address: result?.address ?? undefined,
      isPreInstall: result?.isPreInstall ?? undefined,
      accountType: result?.accountType ?? undefined,
      isACPEligible: result?.isACPEligible ?? undefined,
    })
  );
}

export function* refetchUserInfo() {
  yield call(fetchUserInfo, { refetchData: true });
}

const GET_PENDING_INSTALLATION_DETAILS = gql`
  query getPendingInstallationDetails($refetchData: Boolean) {
    getPendingInstallationDetails(refetchData: $refetchData) {
      isEquipmentShipped
      hasPendingInstallation
      newOfferName
      externalWorkOrderId
      schedule {
        from
        to
      }
    }
  }
`;

function* fetchPendingInstallationDetails() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_PENDING_INSTALLATION_DETAILS,
    variables: { refetchData: true },
  });

  const { data, errors, runtimeError } = response;

  if (runtimeError) {
    yield put(setPendingInstallationError(runtimeError));
  }

  if (errors) {
    yield put(setPendingInstallationError(errors));
  }

  if (data) {
    yield put(setPendingInstallation(data.getPendingInstallationDetails));
  }
}

export function* watchUserInfo() {
  yield all([
    takeLatest(setUser.type, fetchUserInfo),
    takeLatest(getPendingInstallation.type, fetchPendingInstallationDetails),
    takeLatest(refetchUserInfoAction.type, refetchUserInfo),
    takeLatest(updateContactInfoAction.type, updateContactInfo),
    takeLatest(updateContactInfoSMBAction.type, updateContactInfoSMB),
  ]);
}
