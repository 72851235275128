import styles from './styles.module.scss';

export function renderMultilineLoading(lines: number) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      {Array.from({ length: lines }).map((_, index) => (
        <div key={index} className={styles['loadingRow']} />
      ))}
    </div>
  );
}
