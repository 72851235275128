import { ProductType } from '@mfe/shared/schema-types';
import {
  getCharacteristicValue,
  MarketingCharacteristics,
} from '@mfe/shared/util';

export const getDescription = (product: ProductType) => {
  const description =
    getCharacteristicValue(
      product?.marketing_copy?.translations[0]?.characteristics,
      MarketingCharacteristics.OFFER_DESCRIPTION
    ) ??
    product?.description ??
    '';
  return description;
};
