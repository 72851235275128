import styles from './styles.module.scss';
import React from 'react';

export const LoadingCard = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
      <div className={styles['loadingIcon']} />
      <div className={styles['loadingName']} />
    </div>
  );
};
