import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  InlineLink,
  SectionAlert,
  StandAloneLink,
  Surface,
  Txt,
  colors,
} from '@vst/beam';
import { Offer, OpenInNew } from '@vst/beam-icons/icons';
import { format, parse } from 'date-fns';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  AccountType,
  GetCurrentAddOnsPayload,
  Platform,
} from '@mfe/shared/schema-types';

import { selectConfig } from '@mfe/shared/redux/config';
import {
  ADD_ON_STATE,
  useDetectBrowser,
  useScreenResolution,
} from '@mfe/shared/util';
import { AddOnCard, AddonManagementModal } from '@mfe/features/add-ons';
import {
  useShowAddonCustomerSupportMessage,
  ActiveAddonsError,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';

import styles from './Addons.module.scss';

export const AddonsCard = () => {
  const { t } = useTranslation(['Profile', 'Global']);
  const { goTo } = useNavigate();
  const {
    currentAddOns,
    error,
    loading: currentAddOnsLoading,
  } = useSelector(selectAddOns);

  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const browser = useDetectBrowser();

  const { platform } = useSelector(selectConfig);
  const showAddonsShop = useShowAddonsShop();

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const hasAddOns = Boolean(currentAddOns?.length);
  const showAddonCustomerSupportMessage = useShowAddonCustomerSupportMessage();

  const showLink = platform !== Platform.Web || browser === 'MobileWeb';

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const addOnsLink =
    accountType === AccountType.Residential
      ? t('Global:addOnsResidentialLink')
      : t('Global:addOnsSMBLink');

  if (currentAddOnsLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ActiveAddonsError
        title={t('Profile:addOns.title')}
        description={t('Profile:addOns.error.description')}
        refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
      />
    );
  }

  return (
    <div className={styles['container']}>
      <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
        {hasAddOns ? (
          <>
            <div className={styles['header']}>
              <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>
              {showAddonsShop ? (
                <Button onClick={() => goTo('ShopAddons')} variant="secondary">
                  {t('Profile:addOns.shopAddonsButtonLabel')}
                </Button>
              ) : (
                <InlineLink
                  href={addOnsLink}
                  openInNewLocation={true}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    openLinkInNewTab(e, addOnsLink);
                  }}
                >
                  <Button variant="secondary" icon={OpenInNew} iconPos="right">
                    {t('Profile:addOns.seeMoreButton')}
                  </Button>
                </InlineLink>
              )}
            </div>
            <div className={styles['addons']}>
              {currentAddOns?.map((addon, index) => (
                <AddonWithModal addon={addon} key={index} />
              ))}
            </div>
            {showAddonCustomerSupportMessage && (
              <div className={styles['footer']}>
                <Txt variant="bodySmallRegular">
                  {t('Profile:addOns.manageAddons')}
                  {showLink ? (
                    <InlineLink
                      data-cy="call-customer-support-phone-number"
                      variant="primary"
                      href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
                    >
                      {t('Global:callCustomerSupportPhoneNumberDisplay')}
                    </InlineLink>
                  ) : (
                    <Txt variant="bodySmallBold" component="span">
                      {t('Global:callCustomerSupportPhoneNumberDisplay')}
                    </Txt>
                  )}
                </Txt>
              </div>
            )}
          </>
        ) : (
          <NoAddons />
        )}
      </Surface>
    </div>
  );
};

const AddonWithModal = ({ addon }: { addon: GetCurrentAddOnsPayload }) => {
  const {
    description,
    price,
    priceWithDiscount,
    discountName,
    discountEndDate,
    state,
  } = addon;

  const { t } = useTranslation(['Profile', 'Global']);
  const { showRemoveAddonFlow } = useSelector(selectConfig);
  const pendingDeactivation = state.includes(ADD_ON_STATE.PENDING);

  // The parse function reads converts the given string into a Date object representing midnight on the given day, in the local time zone of the JS environment
  const parsedEndDate = discountEndDate
    ? parse(discountEndDate, 'yyyy-MM-dd', new Date())
    : null;

  const formattedEndDate = parsedEndDate
    ? format(parsedEndDate, 'MMMM dd, yyyy')
    : '';

  return (
    <div className={styles['addons__item']}>
      <AddOnCard addon={addon}>
        {description && (
          <Txt variant="smallRegular" color="regular">
            {description}
          </Txt>
        )}
        <div className={styles['pricesAndDiscounts']}>
          {discountName ? (
            <DiscountedPrice
              addOnPrice={price}
              priceWithDiscount={priceWithDiscount}
            />
          ) : (
            <Txt
              variant="bodySmallBold"
              color={Number(price) > 0 ? 'subtle' : 'success'}
            >
              {Number(price) > 0
                ? t('addOns.price', { addOnPrice: price })
                : t('addOns.freePrice')}
            </Txt>
          )}
          {showRemoveAddonFlow && !pendingDeactivation && (
            <ManageLink addon={addon} />
          )}
        </div>
        {discountName && discountEndDate && (
          <SectionAlert variant="success" icon={Offer}>
            <Txt variant="smallRegular" color="regular">
              {t('Profile:addOns.offerAlert', {
                discountName,
                discountEndDate: formattedEndDate,
                addOnPrice: price,
              })}
            </Txt>
          </SectionAlert>
        )}
      </AddOnCard>
    </div>
  );
};

const ManageLink = ({ addon }: { addon: GetCurrentAddOnsPayload }) => {
  const { productInstanceId, name, isBundle } = addon;
  const { t } = useTranslation('Profile');
  const { showModal } = useSelector(selectAddOns);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <StandAloneLink
        variant="primary"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleOpenModal();
        }}
        ml="4px"
        style={{ textDecoration: 'none', display: 'inline-block' }}
      >
        {t('manage.link')}
      </StandAloneLink>
      <AddonManagementModal
        id={productInstanceId}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        addOnName={name}
        isBundleAddOn={isBundle}
        showModal={showModal}
      />
    </>
  );
};

type DiscountedPricesProps = {
  addOnPrice: string;
  priceWithDiscount: number | undefined | null;
};

const DiscountedPrice = ({
  addOnPrice,
  priceWithDiscount,
}: DiscountedPricesProps) => {
  const { t } = useTranslation('Profile');
  return (
    <div>
      {priceWithDiscount && priceWithDiscount > 0 ? (
        <Txt variant="bodySmallBold" color="subtle" component="span" mr="4px">
          {t('addOns.priceWithDiscount', {
            discountPrice: priceWithDiscount.toFixed(2),
          })}
        </Txt>
      ) : (
        <Txt
          variant="bodySmallBold"
          color="subtle"
          component="span"
          mr="4px"
          style={{ color: colors['green'][700] }}
        >
          {t('addOns.freePrice')}
        </Txt>
      )}
      <Txt variant="bodySmallRegular" component="span">
        <s>{t('addOns.price', { addOnPrice })}</s>
      </Txt>
    </div>
  );
};

const NoAddons = () => {
  const { t } = useTranslation(['Profile', 'Overview']);
  const { goTo } = useNavigate();

  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  return (
    <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
      <div className={styles['header']}>
        <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>
      </div>
      <div className={styles['no-add-ons-container']}>
        <Txt variant="bodySmallRegular" color="subtle">
          {t('Profile:addOns.noAddOns')}
        </Txt>
        <Button
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            goTo('ShopAddons');
          }}
        >
          {t('Overview:addOns.shopAddons')}
        </Button>
      </div>
    </Surface>
  );
};

const Loading = () => {
  return (
    <Surface data-cy="loading" className={styles['profile-loading-container']}>
      <div className={styles['loading-title-container']}>
        <div className={styles['loading-title']} />
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>
    </Surface>
  );
};
