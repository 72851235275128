import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Icon, Txt } from '@vst/beam';
import { Check } from '@vst/beam-icons/icons';
import { CustomStandardModal } from '@mfe/shared/components';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

import styles from './styles.module.scss';
import { findAddOn } from '../add-on-card/utils';

type BenefitsAddOnModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
  removeAddOn: (event: any) => void;
  addOnName: string;
};

export const BenefitsAddOnModal: FC<BenefitsAddOnModalProps> = ({
  isOpen,
  onClose,
  handleClick,
  removeAddOn,
  addOnName,
}) => {
  const { t } = useTranslation('ShopAddons');
  const { currentAddOns } = useSelector(selectAddOns);

  const addOn = findAddOn(currentAddOns, addOnName);

  return (
    <CustomStandardModal
      isOpen={isOpen}
      onClose={onClose}
      size={'medium'}
      header={t('benefitsAddOn.header', { addOnName })}
      actions={{
        primary: {
          label: `${t('benefitsAddOn.primaryLabel.text', { addOnName })}`,
          onClick: handleClick,
        },
        secondary: {
          label: t('benefitsAddOn.secondaryLabel.text'),
          onClick: removeAddOn,
        },
      }}
      surfaceProps={{ className: styles['remove-add-on-modal'] }}
    >
      <>
        <Txt
          variant={'bodySmallRegular'}
          className={styles['sub-title']}
          pb={'8px'}
        >
          <Trans
            t={t}
            i18nKey="benefitsAddOn.subText"
            values={{ addOnName: addOnName }}
            components={[<span className={styles['subtitle-addon-name']} />]}
          />
        </Txt>
        <div data-testid="removeOptionalServiceBenefitsList">
          {addOn?.attributes?.map(
            (benefit, index) =>
              benefit?.value && (
                <p key={index} className={styles['benefitsItem']}>
                  <Icon icon={Check} color="green_600" size={20} />
                  <Txt component="span" variant="smallRegular">
                    <Txt
                      ml="4px"
                      variant="smallRegular"
                      data-testid="removeOptionalServiceModalAttribute"
                    >
                      {benefit?.value}
                    </Txt>
                    {benefit?.crossOut && (
                      <Txt variant="smallRegular" ml={'4px'}>
                        (
                        <span className={styles['cross-out-item']}>
                          {benefit?.crossOut}
                        </span>
                        )
                      </Txt>
                    )}
                  </Txt>
                </p>
              )
          )}
        </div>
      </>
    </CustomStandardModal>
  );
};
