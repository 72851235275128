import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCharacteristics,
  getUsage,
  selectPlanInfo,
} from '@mfe/to-be-migrated/redux/plan';
import { CardProps, useDialogType, ModalContext } from '@mfe/shared/components';
import {
  selectAnalytics,
  AnalyticsEventNames,
  selfDescribingEvent,
  getDisplayPlatform,
  inViewSelfDescribingEvent,
  structuredEvent,
  Categories,
  AnalyticsAction,
} from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { postOverviewMessage } from '@mfe/to-be-migrated/redux/utils';
import {
  SESSION_STORAGE_VARIABLES,
  Storage,
  useTrackTiming,
} from '@mfe/shared/util';
import { EventTypes } from '@mfe/services/window-messages';

import { LocalizedUsageSummary } from './localized-usage-summary';

interface UsageSummaryProps extends CardProps {
  navigateToPage: (link: string) => void;
  navigateToChangePlan: () => void;
}

/*
Following ECJ-5356 (turn on feature flag for new design in prod) & ECJ-5353/ECJ-5357 (code removal), this will only be used:
- on the Usage Page in MV
- in SalesForce
*/

const UsageSummary = ({
  navigateToPage,
  navigateToChangePlan,
}: UsageSummaryProps) => {
  const dispatch = useDispatch();

  const {
    user: {
      auth: {
        tokenInfo: { type: tokenType },
      },
    },
  } = useSelector(selectUser);

  const {
    loading: { characteristics: characteristicsLoading, usage: usageLoading },
    data: {
      characteristics: { id },
      usage: { monthlyDataCap },
    },
  } = useSelector(selectPlanInfo);

  const [dialogType, dialogTypeHandlers] = useDialogType();
  const { usageContext, display_platform } = useSelector(selectAnalytics);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  React.useEffect(() => {
    if (!id) dispatch(getCharacteristics());
    if (!monthlyDataCap) dispatch(getUsage());
  }, [dispatch, id, monthlyDataCap]);

  const isLoading = characteristicsLoading || usageLoading;

  useTrackTiming({
    isLoading,
    eventName: 'UsageSummaryCardLoaded',
  });

  const displayContext = React.useMemo(
    () => ({
      schema: 'display_context',
      data: {
        display_zone: 'usage-summary',
        display_platform,
        display_location: 'body',
      },
    }),
    [display_platform]
  );

  React.useEffect(() => {
    if (!isLoading)
      dispatch(
        inViewSelfDescribingEvent({
          selector: '.usage-summary',
          eventName: AnalyticsEventNames.UsageSummaryDisplayed,
          data: {
            display_zone: 'usage-summary',
            display_platform: getDisplayPlatform(tokenType),
          },
          context: [usageContext, displayContext],
        })
      );
  }, [dispatch, isLoading]);

  const handleChangePlan = React.useCallback(() => {
    dispatch(
      selfDescribingEvent({
        eventName: AnalyticsEventNames.UsageSummaryChangePlanSelected,
        data: {
          display_zone: 'usage-summary',
          display_platform,
        },
        context: [displayContext, usageContext],
      })
    );
    navigateToChangePlan();
  }, [
    dispatch,
    display_platform,
    displayContext,
    usageContext,
    navigateToChangePlan,
  ]);

  const handlePlanDetailsClick = React.useCallback(() => {
    dispatch(
      structuredEvent({
        category: Categories.PlanDetails,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          context: [usageContext, displayContext],
        },
      })
    );

    Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
    navigateToPage('Profile');
  }, [usageContext, displayContext]);

  const handleNavigateToPlanPage = React.useCallback(() => {
    if (window && navigateToPage) {
      navigateToPage('PlanUsage');
    }
    dispatch(postOverviewMessage({ eventType: EventTypes.VisitPlanUsage }));
  }, [dispatch, navigateToPage]);

  return (
    <ModalContext.Provider value={{ dialogType, dialogTypeHandlers }}>
      <LocalizedUsageSummary
        isLoading={isLoading}
        userLocale={userLocale}
        handleChangePlan={handleChangePlan}
        handlePlanDetailsClick={handlePlanDetailsClick}
        handleNavigateToPlanPage={handleNavigateToPlanPage}
      />
    </ModalContext.Provider>
  );
};

export default UsageSummary;
