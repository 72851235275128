import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InlineLink, Txt } from '@vst/beam';
import { useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { AccountType, Platform } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import styles from './TermsAndConditions.module.scss';
import { CustomStandardModal } from '@mfe/shared/components';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const TermsAndConditionsModal = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.terms.modal',
  });

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);
  const isSMB = accountType === AccountType.Smb;

  if (isSMB) {
    return (
      <TermsAndConditionsModalWrapper
        isOpen={isOpen}
        handleClose={handleClose}
        header={t('title')}
        content={<SMBContent />}
      />
    );
  }

  return (
    <TermsAndConditionsModalWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      header={t('title')}
      content={<ResidentialContent />}
    />
  );
};

type WrapperProps = Props & {
  header: string;
  content: React.ReactNode;
};

const TermsAndConditionsModalWrapper = ({
  isOpen,
  handleClose,
  header,
  content,
}: WrapperProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.terms.modal',
  });

  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isMedium || isLarge || isExtraLarge;

  return (
    <CustomStandardModal
      isOpen={isOpen}
      onClose={handleClose}
      header={header}
      actions={{ primary: { label: t('button'), onClick: handleClose } }}
      size={isDesktop ? 'medium' : 'xLarge'}
    >
      <div className={styles['modalContent']}>{content}</div>
    </CustomStandardModal>
  );
};

const ResidentialContent = () => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.terms.modal',
  });

  return (
    <>
      <Txt component="p" variant="tinyRegular">
        {t('content.intro1')}
      </Txt>
      <Txt component="p" variant="tinyRegular">
        <Trans
          ns="NewChangePlan"
          i18nKey="orderReview.details.terms.modal.content.intro2"
        />
      </Txt>

      <Txt component="p" variant="tinyBold">
        {t('content.servicePlansWithModem.title')}
      </Txt>
      <Txt component="p" variant="tinyRegular">
        {t('content.servicePlansWithModem.intro')}
      </Txt>
      <ol>
        {Object.values(
          t('content.servicePlansWithModem.list', {
            returnObjects: true,
          })
        ).map((key: string, index: number) => (
          <li key={index} style={{ fontSize: 12 }}>
            <Txt component="p" variant="tinyRegular">
              {key}
            </Txt>
          </li>
        ))}
      </ol>
      <Txt component="p" variant="tinyRegular">
        {t('content.servicePlansWithModem.remainingTerms')}
      </Txt>

      <Txt component="p" variant="tinyBold">
        {t('content.anyToUnleashed.title')}
      </Txt>
      <ul>
        {Object.values(
          t('content.anyToUnleashed.list', { returnObjects: true })
        ).map((key: string, index: number) => (
          <li key={index}>
            <Txt component="p" variant="tinyRegular">
              {key}
            </Txt>
          </li>
        ))}
      </ul>

      <Txt component="p" variant="tinyBold">
        {t('content.anyToChoice.title')}
      </Txt>
      <Txt component="p" variant="tinyRegular">
        {t('content.anyToChoice.intro')}
      </Txt>
      <ul>
        <li>
          <Txt component="p" variant="tinyRegular">
            {t('content.anyToChoice.plans')}
          </Txt>
        </li>
      </ul>
    </>
  );
};

const SMBContent = () => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.terms.modal',
  });
  const dispatch = useDispatch();

  const { platform } = useSelector(selectConfig);

  return (
    <>
      <ul>
        {Object.values(t('content.smb.list', { returnObjects: true })).map(
          (text: string | { regular: string; bold: string }, index: number) => {
            if (typeof text === 'object') {
              return (
                <li key={index}>
                  <Txt component="p" variant="tinyRegular">
                    <span>{text.regular}</span>{' '}
                    <Txt component="span" variant="tinyBold">
                      {text.bold}
                    </Txt>
                  </Txt>
                </li>
              );
            }

            return (
              <li key={index}>
                <Txt component="p" variant="tinyRegular">
                  {text}
                </Txt>
              </li>
            );
          }
        )}
      </ul>

      <Txt component="p" variant="tinyRegular">
        {t('content.smb.table.intro1')}
      </Txt>
      <Txt component="p" variant="tinyRegular">
        {t('content.smb.table.intro2')}
      </Txt>
      <table className={styles['table']}>
        <thead>
          <tr>
            {Object.values(
              t('content.smb.table.columns', { returnObjects: true })
            ).map((text: string, index: number) => (
              <th key={index}>
                <Txt component="span" variant="tinyBold">
                  {text}
                </Txt>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {Object.values(
            t('content.smb.table.rows', { returnObjects: true })
          ).map(
            (
              { planName, threshold }: { planName: string; threshold: string },
              index: number
            ) => {
              return (
                <tr key={index}>
                  <td>
                    <Txt component="span" variant="tinyRegular">
                      {planName}
                    </Txt>
                  </td>
                  <td>
                    <Txt component="span" variant="tinyRegular">
                      {threshold}
                    </Txt>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>

      <Txt component="p" variant="tinyBold">
        {t('content.smb.anyToChoice.title')}
      </Txt>
      <ul>
        <li>
          <Txt component="p" variant="tinyRegular">
            {t('content.smb.anyToChoice.officeHours')}
          </Txt>
        </li>
        <li>
          <Txt component="p" variant="tinyRegular">
            <span>
              {t('content.smb.anyToChoice.explanation')}{' '}
              <InlineLink
                style={{ lineHeight: 'inherit' }}
                onClick={(e: any) => {
                  if (platform === Platform.Web) {
                    return;
                  }

                  e.preventDefault();
                  dispatch(openInNewTab(t('content.smb.anyToChoice.url')));
                }}
                href={t('content.smb.anyToChoice.url')}
                target="_blank"
              >
                {t('content.smb.anyToChoice.url')}
              </InlineLink>
            </span>
          </Txt>
        </li>
      </ul>

      <Txt component="p" variant="tinyBold">
        {t('content.smb.anyToChoiceBackup.title')}
      </Txt>
      <ul>
        <li>
          <Txt component="p" variant="tinyRegular">
            {t('content.smb.anyToChoiceBackup.explanation')}
          </Txt>
        </li>
      </ul>
    </>
  );
};
