import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  IconComponentType,
  Surface,
  Txt,
  StandAloneLink,
  InlineLink,
} from '@vst/beam';
import {
  CustomStandardModal,
  ModalType,
  ModalTypeEnum,
} from '@mfe/shared/components';
import { OpenInNew, Phone } from '@vst/beam-icons/icons';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectAddOns, setShowModal } from '@mfe/to-be-migrated/redux/addOns';

import {
  CancelSubscriptionErrorModal,
  CancelSubscriptionModal,
} from '../remove-add-ons';
import { findAddOn } from './utils';
import styles from './styles.module.scss';
import { BenefitsAddOnModal } from '../remove-add-ons/BenefitsAddOnModal';

type SupportOptionItemProps = {
  text: string;
  linkText: string;
  href: string;
  icon: IconComponentType;
  dataCy: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const SupportOptionItem = ({
  icon,
  text,
  linkText,
  onClick,
  href,
  dataCy,
}: SupportOptionItemProps) => (
  <Surface
    className={styles['support-option-item']}
    radius="16px"
    px={'24px'}
    py={'24px'}
  >
    <Txt variant={'bodyLargeRegular'} color={'regular'} component="span">
      {text}
    </Txt>
    <StandAloneLink
      variant="primary"
      href={href}
      onClick={onClick}
      icon={icon}
      style={{ textDecoration: 'none', fontWeight: '600' }}
      data-cy={dataCy}
    >
      {linkText}
    </StandAloneLink>
  </Surface>
);

type AddonManagementModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  addOnName: string;
  showModal?: ModalType;
  isBundleAddOn?: boolean;
};

enum ManagementModal {
  Benefits = 'BENEFITS',
  Subscription = 'SUBSCRIPTION',
}

export const AddonManagementModal = ({
  id,
  isOpen,
  onClose,
  addOnName,
  showModal,
  isBundleAddOn,
}: AddonManagementModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Profile');
  const { currentAddOns } = useSelector(selectAddOns);

  const [modal, setModal] = useState<ManagementModal | null>(null);
  const closeModal = () => setModal(null);
  const isCancelSubscriptionErrorModal =
    showModal === ModalTypeEnum.CancelSubscriptionError;

  const addOn = findAddOn(currentAddOns, addOnName);

  const hasValidAttributes =
    addOn?.attributes?.some((benefit) => benefit?.value) ?? false;

  const handleHelpCenterClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const url = t('visitHelpCenter.url');
    dispatch(openInNewTab(url));
  };

  const handleCancelSubscription = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (hasValidAttributes) {
      setModal(ManagementModal.Benefits);
    } else {
      setModal(ManagementModal.Subscription);
    }
    onClose();
  };

  const handleBenefitsModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setModal(ManagementModal.Subscription);
    onClose();
  };

  if (isCancelSubscriptionErrorModal) {
    return (
      <CancelSubscriptionErrorModal
        isOpen={isCancelSubscriptionErrorModal}
        onClose={() => {
          dispatch(setShowModal(null));
        }}
        addOnName={addOnName}
      />
    );
  }
  return (
    <>
      <CustomStandardModal
        isOpen={isOpen}
        header={t('manageAddonModal.header', { addOnName })}
        actions={{
          primary: { label: t('manageAddonModal.label'), onClick: onClose },
        }}
        onClose={onClose}
        size="medium"
      >
        <div>
          <Txt variant={'bodyLargeRegular'} color={'regular'}>
            {t('manageAddonModal.description', { addOnName })}
          </Txt>
          <Surface mb={'24px'} className={styles['support-options-container']}>
            <SupportOptionItem
              icon={OpenInNew}
              text={t('visitHelpCenter.text')}
              linkText={t('visitHelpCenter.link')}
              onClick={handleHelpCenterClick}
              dataCy="visit-help-center"
              href={'#'}
            />
            <SupportOptionItem
              icon={Phone}
              text={t('callCustomerCare.text')}
              linkText={t('callCustomerCare.link')}
              href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
              dataCy="call-customer-care"
            />
          </Surface>
          {!isBundleAddOn && (
            <Txt
              variant={'bodySmallRegular'}
              color={'regular'}
              component="span"
            >
              {t('cancelSubscription.title')}
              <InlineLink
                data-cy="manage-addon-link"
                variant="primary"
                href="#"
                onClick={handleCancelSubscription}
                style={{ textDecoration: 'none' }}
                ml={'4px'}
              >
                {t('cancelSubscription.link')}
              </InlineLink>
            </Txt>
          )}
        </div>
      </CustomStandardModal>

      {modal === ManagementModal.Benefits && (
        <BenefitsAddOnModal
          isOpen={modal === ManagementModal.Benefits}
          onClose={closeModal}
          handleClick={closeModal}
          removeAddOn={handleBenefitsModal}
          addOnName={addOnName}
        />
      )}
      {modal === ManagementModal.Subscription && (
        <CancelSubscriptionModal
          id={id}
          isOpen={modal === ManagementModal.Subscription}
          onClose={closeModal}
          addOnName={addOnName}
        />
      )}
    </>
  );
};
