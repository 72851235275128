/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Address,
  Coordinates,
  Etf,
  RetentionOffer,
} from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

import { ScrubbedAddressState } from '../scrubbedAddress';

type SurveyValues = {
  disconnectReason: string;
  otherReason: string;
  provider: string;
};

type ErrorType = ErrorsObj | RuntimeError | null;

type DisconnectState = {
  etf: {
    loading: boolean;
    data: Etf[] | null;
    error: ErrorType;
  };
  retentionOffers: {
    loading: boolean;
    data: RetentionOffer[] | null;
    error: ErrorType;
  };
  disconnectViasatInternet: {
    loading: boolean;
    success: boolean | null;
    error: ErrorType;
    disconnectReason?: string;
  };
  purchaseRetentionOffer: {
    loading: boolean;
    success: boolean;
    data: PurchaseRetentionOffer | null;
    error: unknown | string | null;
  };
  survey: SurveyValues;
  hasCanceledDisconnectOrder: boolean;
};

type PurchaseRetentionOffer = {
  shoppingCartId: string;
  rootProductTypeId: string;
  productTypeId: string;
  coordinates: Coordinates;
  address: Address;
};

const initialState: DisconnectState = {
  etf: {
    loading: false,
    data: null,
    error: null,
  },
  retentionOffers: {
    loading: false,
    data: null,
    error: null,
  },
  disconnectViasatInternet: {
    loading: false,
    success: null,
    error: null,
    disconnectReason: '',
  },
  purchaseRetentionOffer: {
    loading: false,
    success: false,
    data: null,
    error: null,
  },
  survey: {
    disconnectReason: '',
    otherReason: '',
    provider: '',
  },
  hasCanceledDisconnectOrder: false,
};

export const disconnectSlice = createSlice({
  name: 'disconnect',
  initialState,
  reducers: {
    getEtf: (state) => {
      state.etf.loading = true;
    },
    setEtf: (state, action: PayloadAction<Etf[] | null>) => {
      state.etf.loading = false;
      state.etf.data = action.payload;
    },
    setEtfError: (state, action: PayloadAction<ErrorType>) => {
      state.etf.loading = false;
      state.etf.error = action.payload;
    },
    processScrubResult: (state, action: ProcessScrubAction) => state,
    getRetentionOffers: (state) => {
      state.retentionOffers.loading = true;
    },
    setRetentionOffers: (
      state,
      action: PayloadAction<RetentionOffer[] | null>
    ) => {
      state.retentionOffers.loading = false;
      state.retentionOffers.data = action.payload;
    },
    setRetentionOffersError: (state, action: PayloadAction<ErrorType>) => {
      state.retentionOffers.loading = false;
      state.retentionOffers.error = action.payload;
    },
    getPurchaseRetentionOffers: (
      state,
      action: PayloadAction<PurchaseRetentionOffer>
    ) => {
      state.purchaseRetentionOffer.loading = true;
      state.purchaseRetentionOffer.data = action.payload;
    },
    setPurchaseRetentionOfferSuccess: (state, action: { payload: boolean }) => {
      state.purchaseRetentionOffer.loading = false;
      state.purchaseRetentionOffer.success = action.payload;
    },
    setPurchaseRetentionOfferError: (
      state,
      action: PayloadAction<unknown | string>
    ) => {
      state.purchaseRetentionOffer.loading = false;
      state.purchaseRetentionOffer.error = action.payload;
    },
    setSurveyValues: (state, action: PayloadAction<Partial<SurveyValues>>) => {
      state.survey = { ...state.survey, ...action.payload };
    },
    resetSurveyValues: (state) => {
      state.survey = initialState.survey;
    },
    disconnectViasatInternet: (state, action: PayloadAction<string>) => {
      state.disconnectViasatInternet.loading = true;
    },
    setCancelOrder: (state) => state,
    setDisconnectError: (state, action: PayloadAction<ErrorType>) => {
      state.disconnectViasatInternet.error = action.payload;
      state.disconnectViasatInternet.loading = false;
    },
    setViasatInternetDisconnected: (state) => {
      state.disconnectViasatInternet.loading = false;
      state.disconnectViasatInternet.success = true;
    },
    resetDisconnectInternet: (state) => {
      state.disconnectViasatInternet.success = null;
      state.disconnectViasatInternet.loading = false;
      state.disconnectViasatInternet.error = null;
      state.disconnectViasatInternet.disconnectReason = '';
    },
    setScheduledToDisconnectGlobalAlert: (state) => state,
    setHasCancelledDisconnectOrder: (state, action: PayloadAction<boolean>) => {
      state.hasCanceledDisconnectOrder = action.payload;
    },
  },
});

export type ProcessScrubAction = PayloadAction<{
  rawInput: Address;
  scrubbedAddress: ScrubbedAddressState['scrubbedAddress'];
}>;

export const {
  getEtf,
  setEtf,
  setEtfError,
  processScrubResult,
  getRetentionOffers,
  setRetentionOffers,
  setRetentionOffersError,
  setSurveyValues,
  getPurchaseRetentionOffers,
  setPurchaseRetentionOfferSuccess,
  setPurchaseRetentionOfferError,
  resetSurveyValues,
  disconnectViasatInternet,
  setScheduledToDisconnectGlobalAlert,
  setDisconnectError,
  setViasatInternetDisconnected,
  resetDisconnectInternet,
  setCancelOrder,
  setHasCancelledDisconnectOrder,
} = disconnectSlice.actions;

export default disconnectSlice.reducer;

export const selectEtf = (state: { disconnect: DisconnectState }) => ({
  data: state.disconnect.etf.data?.[0],
  loading: state.disconnect.etf.loading,
  error: state.disconnect.etf.error,
});

export const selectRetentionOffers = (state: {
  disconnect: DisconnectState;
}) => ({
  data: state.disconnect.retentionOffers.data,
  loading: state.disconnect.retentionOffers.loading,
  error: state.disconnect.retentionOffers.error,
});

export const selectPurchaseRetentionOffers = (state: {
  disconnect: DisconnectState;
}) => ({
  success: state.disconnect.purchaseRetentionOffer.success,
  data: state.disconnect.purchaseRetentionOffer.data,
  loading: state.disconnect.purchaseRetentionOffer.loading,
  error: state.disconnect.purchaseRetentionOffer.error,
});

export const selectSurveyValues = (state: { disconnect: DisconnectState }) =>
  state.disconnect.survey;

export const selectDisconnectInternet = (state: {
  disconnect: DisconnectState;
}) => ({
  loading: state.disconnect.disconnectViasatInternet.loading,
  success: state.disconnect.disconnectViasatInternet.success,
  error: state.disconnect.disconnectViasatInternet.error,
  hasCanceledDisconnectOrder: state.disconnect.hasCanceledDisconnectOrder,
});
