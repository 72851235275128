import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

const PriceWithDiscount = ({ promoRate }: { promoRate: number }) => {
  const { t } = useTranslation('ShopAddons');
  return promoRate > 0 ? (
    <Txt
      variant={'bodySmallBold'}
      color={'success'}
      data-testid="priceWithoutDiscount"
      component="span"
    >
      {`$${promoRate.toFixed(2)}`}
      <Txt
        variant={'bodySmallBold'}
        color={'success'}
        component={'span'}
        data-testid="durationPeriod"
      >
        {t('DurationPeriod.Month')}
      </Txt>
    </Txt>
  ) : (
    <Txt
      variant={'bodySmallBold'}
      color={'success'}
      data-testid="freeText"
      component="span"
    >
      {t('FreeText')}
    </Txt>
  );
};

const PromoWithoutDiscount = ({ price }: { price: number }) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <Txt
      variant={'bodySmallBold'}
      color={'regular'}
      data-testid="priceWithoutDiscount"
      component="span"
    >
      {price ? (
        <>
          - {price.toFixed(2)}
          <Txt
            component={'span'}
            variant={'smallBold'}
            color={'regular'}
            data-testid="durationPeriod"
          >
            {t('DurationPeriod.Month')}
          </Txt>
        </>
      ) : (
        <Txt variant={'smallBold'} color={'success'} data-testid="freeAddOn">
          {t('FreeText')}
        </Txt>
      )}
    </Txt>
  );
};

interface PromoDetailsProps {
  promotionAmount: number;
  price: number;
  duration: number;
}

export const PromoDetails = ({
  promotionAmount,
  price,
  duration,
}: PromoDetailsProps) => {
  const { t } = useTranslation('ShopAddons');
  const promoRate = price + promotionAmount;

  return promotionAmount ? (
    <>
      <PriceWithDiscount promoRate={promoRate} />
      <Txt data-testid="durationPeriodInfo" component="span">
        <Txt
          variant={'bodySmallBold'}
          color={'success'}
          data-testid="addonPriceDiscountDurationPeriod"
          ml={'4px'}
          component="span"
        >
          {t('priceBeforePromotion', { DISCOUNT_PERIOD: `${duration}` })}
        </Txt>
      </Txt>
    </>
  ) : (
    <PromoWithoutDiscount price={price} />
  );
};
