import { useTranslation } from 'react-i18next';

import { Surface, Txt, colors } from '@vst/beam';
import { DownloadOutlined } from '@vst/beam-icons/icons';

import { showTitleAddons, useScreenResolution } from '@mfe/shared/util';
import { GetPlanOffersPayload } from '@mfe/shared/schema-types';

import { PrimaryAttribute } from '../PlanCard/PrimaryAttribute';
import { OneTimeFees } from '../OrderReview/OneTimeFees';
import { InternetSummary } from '../OrderReview/InternetSummary';

import { TitleAddOns } from '../../shared/index';

import styles from './CartSummary.module.scss';

interface CartSummaryProps {
  selectedPlan: GetPlanOffersPayload;
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
  requiresWorkOrder: boolean;
}

export const CartSummary = ({
  selectedPlan,
  handleBackNavigation,
  requiresWorkOrder,
}: CartSummaryProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.cartDetails',
  });

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const { characteristics: sourceCharacteristics } = selectedPlan;

  const characteristics = {
    ...sourceCharacteristics,
    description: selectedPlan.description,
    downloadSpeed: sourceCharacteristics.downloadRangeText
      ? undefined
      : sourceCharacteristics.downloadSpeed,
  };

  const showTitleAddOns = showTitleAddons(
    characteristics.family,
    characteristics.titleAddOns
  );

  return (
    <Surface
      p="24px"
      variant="primary"
      data-cy="cart-summary-card"
      className={styles['cart-summary']}
      radius={isMobile ? undefined : '16px'}
    >
      <PrimaryAttribute />
      {(characteristics.downloadSpeed || showTitleAddOns) && (
        <div>
          {characteristics.downloadSpeed && (
            <DownloadSpeedCharacteristic
              downloadSpeed={characteristics.downloadSpeed}
              downloadUnits={characteristics.downloadUnits || ''}
            />
          )}
          {showTitleAddOns && (
            <TitleAddOns
              characteristic={characteristics.titleAddOns as string}
              isCartTitleAddOns={true}
            />
          )}
        </div>
      )}

      <hr />
      <Txt variant="heading6" data-cy="new-monthly-charges">
        {t('pricingDetails.internetTotal')}
      </Txt>
      <InternetSummary
        handleBackNavigation={handleBackNavigation}
        planName={sourceCharacteristics.offerName}
      />
      <Txt variant="smallRegular" color="subtle">
        {t('nextBillInfo')}
      </Txt>
      {requiresWorkOrder && <OneTimeFees selectedPlan={selectedPlan} />}
      <Txt variant="tinyRegular" color="subtle">
        {t('pricingInfo')}
      </Txt>
    </Surface>
  );
};

const DownloadSpeedCharacteristic = ({
  downloadSpeed,
  downloadUnits,
}: {
  downloadSpeed: string;
  downloadUnits: string;
}) => {
  const { t } = useTranslation('NewChangePlan');
  return (
    <Surface
      className={styles['download-speed-container']}
      data-cy="download-speeds-attribute"
    >
      <DownloadOutlined
        style={{ color: colors['gray'][600] }}
        width={20}
        height={20}
      />
      <Surface className={styles['text-container']}>
        <Txt variant="smallBold" component="span">
          {downloadSpeed} {downloadUnits}
        </Txt>
        <Txt variant="smallRegular" component="span">
          {t('orderReview.cartDetails.downloadSpeeds')}
        </Txt>
      </Surface>
    </Surface>
  );
};
