import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Surface, Txt, Button, StandAloneLink } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';

import {
  SESSION_STORAGE_VARIABLES,
  Storage,
  useScreenResolution,
} from '@mfe/shared/util';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectConfig } from '@mfe/shared/redux/config';
import useNavigate from '@mfe/services/navigation';
import {
  ActiveAddonsError,
  useShowAddonCustomerSupportMessage,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { AddOnCard, AddOnType } from '@mfe/features/add-ons';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { CustomerSupportMessage } from './customer-support-message';
import { NoAddons } from './no-add-ons';

import styles from './styles.module.scss';

export const OverviewActiveAddons = (): JSX.Element | null => {
  const { t } = useTranslation(['Overview', 'Global']);
  const dispatch = useDispatch();

  const {
    loading: currentAddOnsLoading,
    currentAddOns,
    error,
  } = useSelector(selectAddOns);

  const showAddonsShop = useShowAddonsShop();
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const hasAddOns = Boolean(currentAddOns?.length);
  const numberOfAddOns = currentAddOns?.length || 0;
  const showAddonCustomerSupportMessage = useShowAddonCustomerSupportMessage();

  const containerClass = `${styles['addons-container']} ${
    numberOfAddOns > 1 ? '' : styles['single-column']
  }`;

  if (!showAddonsShop) return null;

  if (currentAddOnsLoading) return <Loading />;

  if (error) {
    return (
      <ActiveAddonsError
        title={t('Profile:addOns.title')}
        description={t('Profile:addOns.error.description')}
        refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
      />
    );
  }

  return (
    <Surface data-cy="addons-container" className={styles['container']}>
      <div className={styles['header']}>
        <Txt variant="bodyLargeBold">{t('addOns.title')}</Txt>
        {!isMobile && (
          <div className={styles['header-buttons']}>
            <MyAddonsButtons />
          </div>
        )}
      </div>

      {hasAddOns ? (
        <>
          <Surface className={containerClass}>
            {currentAddOns?.map((addon, index) => (
              <div className={styles['addonCard']}>
                <AddOnCard key={index} addon={addon}>
                  {addon.name === AddOnType.ViasatVoice && (
                    <StandAloneLink
                      data-cy="manage-link"
                      variant="secondary"
                      icon={OpenInNew}
                      iconPos="right"
                      href=""
                      style={{ textDecoration: 'none' }}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        dispatch(openInNewTab(t('addOns.alianzaLink')));
                      }}
                    >
                      {t('addOns.manage')}
                    </StandAloneLink>
                  )}
                </AddOnCard>
              </div>
            ))}
          </Surface>
          {isMobile && (
            <div className={styles['header-buttons']}>
              <MyAddonsButtons />
            </div>
          )}
          {showAddonCustomerSupportMessage && <CustomerSupportMessage />}
        </>
      ) : (
        <NoAddons />
      )}
    </Surface>
  );
};

const MyAddonsButtons = () => {
  const { showAddonsShop } = useSelector(selectConfig);
  const { currentAddOns } = useSelector(selectAddOns);
  const hasAddOns = Boolean(currentAddOns?.length);

  if (showAddonsShop) {
    return <MyAddonsButtonsNew />;
  }

  if (!hasAddOns) {
    return null;
  }

  return <MyAddonsButtonsOld />;
};

const MyAddonsButtonsOld = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();

  return (
    <Button
      variant="secondary"
      icon={OpenInNew}
      iconPos="right"
      onClick={(e) => {
        e.preventDefault();
        Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
        goTo('Profile');
      }}
    >
      {t('Overview:addOns.button')}
    </Button>
  );
};

const MyAddonsButtonsNew = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();
  const { currentAddOns } = useSelector(selectAddOns);
  const hasAddOns = Boolean(currentAddOns?.length);

  if (!hasAddOns) return null;

  return (
    <>
      <Button
        variant="tertiary"
        onClick={(e) => {
          e.preventDefault();
          Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
          goTo('Profile');
        }}
      >
        {t('Overview:addOns.manageAddonsButtonLabel')}
      </Button>

      <Button
        variant="secondary"
        onClick={(e) => {
          e.preventDefault();
          goTo('ShopAddons');
        }}
      >
        {t('Overview:addOns.shopAddonsButtonLabel')}
      </Button>
    </>
  );
};

const Loading = () => {
  return (
    <Surface data-cy="loading" className={styles['loading-container']}>
      <div className={styles['loading-title']} />
      <div className={styles['loading-addon-section']}>
        <div className={styles['icon']} />
        <div className={styles['name']} />
        <div className={styles['desc']} />
      </div>
      <div className={styles['loading-footer']} />
    </Surface>
  );
};
