import { gql } from '@apollo/client';

export const CHARACTERISTICS_FRAGMENT = gql`
  fragment CharacteristicsFragment on Characteristic {
    name
    value
  }
`;

export const MARKETING_COPY_FRAGMENT = gql`
  fragment MarketingCopyFragment on MarketingCopy {
    ui_behaviors {
      characteristics {
        ...CharacteristicsFragment
      }
    }
    translations {
      language
      characteristics {
        ...CharacteristicsFragment
      }
    }
  }
  ${CHARACTERISTICS_FRAGMENT}
`;

export const PRICES_FRAGMENT = gql`
  fragment PricesFragment on ProductTypePrice {
    amount {
      value
    }
  }
`;

export const DISCOUNTS_FRAGMENT = gql`
  fragment DiscountsFragment on ProductTypeDiscount {
    name
    duration
    amount {
      value
      currency {
        minor_units
        numeric_code
        major_unit_symbol
        alphabetic_code
      }
    }
  }
`;
