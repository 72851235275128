import { FormEventHandler, useEffect, useState } from 'react';
import { ConventionalSelect, RadioButton, RadioGroup } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSurveyValues,
  setSurveyValues,
} from '@mfe/to-be-migrated/redux/disconnect';

export const Survey = () => {
  return (
    <>
      <DisconnectionReason />
      <NewProviderOption />
    </>
  );
};

const DisconnectionReason = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');
  const surveyValues = useSelector(selectSurveyValues);

  const [reason, setReason] = useState<string>('');
  const [showOtherOptions, setShowOtherOptions] = useState(false);

  const otherOptionValue = t('survey.other');

  useEffect(() => {
    const shouldShowOtherOptions =
      surveyValues.disconnectReason.includes(otherOptionValue) ||
      reason.includes(otherOptionValue);

    setShowOtherOptions(shouldShowOtherOptions);
  }, [reason]);

  const mainOptions = Object.values(
    t('survey.reason.list.main', { returnObjects: true })
  ).map((key: string) => ({
    label: key,
    value: key,
    id: key,
  }));

  const otherOptions = Object.values(
    t('survey.reason.list.other', { returnObjects: true })
  ).map((key: string) => ({
    label: key,
    value: key,
    id: key,
  }));

  const handleChange: FormEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    const disconnectReason = target.value;
    setReason(disconnectReason);

    // If the user selects a different option, reset selected other reason too, if applicable
    const otherReason =
      disconnectReason !== otherOptionValue ? '' : surveyValues.otherReason;
    dispatch(setSurveyValues({ disconnectReason, otherReason }));
  };

  const handleOtherReasonSelection = (otherReason: string) => {
    dispatch(setSurveyValues({ otherReason }));
  };

  return (
    <>
      <ConventionalSelect
        id="main-reasons"
        fluid
        name="main-reasons"
        labelProps={{ labelText: t('survey.reason.label') }}
        options={mainOptions}
        onChange={handleChange}
        value={surveyValues.disconnectReason}
      />
      {showOtherOptions && (
        <RadioGroup
          id="other-reasons"
          name="other-reasons"
          labelProps={{ labelText: t('survey.otherReasons') }}
          options={otherOptions}
        >
          {otherOptions.map((option) => {
            const value = surveyValues.otherReason ?? option.value;
            const checked = surveyValues.otherReason === option.value;
            return (
              <RadioButton
                label={option.label}
                value={value}
                key={option.id}
                checked={checked}
                onClick={() => handleOtherReasonSelection(option.value)}
              />
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

const NewProviderOption = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');
  const surveyValues = useSelector(selectSurveyValues);

  const options = Object.values(
    t('survey.provider.list', { returnObjects: true })
  ).map((key: string) => ({
    label: key,
    value: key,
    id: key,
  }));

  const handleProviderSelection: FormEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    const provider = target.value;
    dispatch(setSurveyValues({ provider }));
  };

  return (
    <ConventionalSelect
      id="providers"
      name="providers"
      fluid
      labelProps={{ labelText: t('survey.provider.label') }}
      options={options}
      onChange={handleProviderSelection}
      value={surveyValues.provider}
    />
  );
};
