import { Suspense, lazy, useCallback } from 'react';
import { Button, Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AnalyticsEventNames,
  Categories,
  DataLayerEventName,
  pushToAdobeDataLayer,
  selectAnalytics,
  selfDescribingEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { createBuyMoreInitiated, BuyMore } from '@mfe/features/buy-more';
import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  useModalContext,
  ModalLoading,
  useCanChangePlan,
  useCanPlaceOrders,
} from '@mfe/shared/components';

import styles from './styles.module.scss';

const ModalComponent = lazy(() => import('../modal'));

export const AddDataBoostSection = () => {
  const { t } = useTranslation('Usage');
  const dispatch = useDispatch();

  const { dialogType, dialogTypeHandlers } = useModalContext();
  const { resetDialogType, setBuyMoreDialog } = dialogTypeHandlers;

  const { usageContext, display_platform } = useSelector(selectAnalytics);

  const handleAddDataBoost = useCallback(() => {
    const displayContext = {
      schema: 'display_context',
      data: {
        display_zone: 'usage-summary',
        display_platform,
        display_location: 'body',
      },
    };

    dispatch(
      selfDescribingEvent({
        eventName: AnalyticsEventNames.UsageSummaryBuyMoreSelected,
        data: {
          display_zone: 'usage-summary',
          display_platform,
        },
        context: [displayContext, usageContext],
      })
    );

    // TODO: This will need updating because we can also dispatch this from overview (but not from plan details)
    const buyMoreInitiated = createBuyMoreInitiated('usage_page');

    dispatch(
      selfDescribingEvent({
        eventName: AnalyticsEventNames.BUY_MORE_INITIATED,
        data: { source: 'usage_page' }, // TODO: This will need updating because we can also dispatch this from overview
        context: [buyMoreInitiated.context],
      })
    );
    pushToAdobeDataLayer({
      event: DataLayerEventName.FORM_START,
      event_name: DataLayerEventName.FORM_START,
      event_label: `${AnalyticsEventNames.BUY_MORE_INITIATED}`,
      event_category: Categories.BuyMore,
    });

    setBuyMoreDialog();
  }, [dispatch, setBuyMoreDialog, usageContext, display_platform]);

  const { localeFetched } = useSelector(selectLocale);
  const {
    data: {
      characteristics: { isBuyMoreAvailable },
    },
  } = useSelector(selectPlanInfo);
  const { canPlaceOrders } = useCanPlaceOrders();
  const canChangePlan = useCanChangePlan();
  const isButtonDisabled =
    (!canChangePlan || !isBuyMoreAvailable || !canPlaceOrders) ?? false;

  const textColor = isButtonDisabled ? 'disabled' : 'regular';

  return (
    <div data-cy="buy-more-container" className={styles['buyMoreContainer']}>
      <Txt mr="24px" variant="smallRegular" color={textColor}>
        {t('buyMore.text')}
      </Txt>
      <Button
        data-cy="buy-more-button"
        variant="secondary"
        theme={'light'}
        buttonSize="small"
        onClick={handleAddDataBoost}
        disabled={isButtonDisabled}
      >
        <Txt
          style={{ whiteSpace: 'nowrap' }}
          variant="labelSmall"
          color={textColor}
        >
          {t('buyMore.cta')}
        </Txt>
      </Button>
      {dialogType && (
        <Suspense
          fallback={
            !localeFetched && dialogType ? (
              <ModalLoading onCancel={resetDialogType} />
            ) : null
          }
        >
          <ModalComponent type={dialogType}>
            <BuyMore />
          </ModalComponent>
        </Suspense>
      )}
    </div>
  );
};
