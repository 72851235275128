import { TFunction } from 'i18next';
import { format, subDays } from 'date-fns';

import {
  ADDON_PRODUCT_KIND,
  STATIC_IP_ADDON_KINDS,
  VOICE_ADDONS_KINDS,
} from '@mfe/shared/util';
import { BillingAccount, IndoorUnit } from '@mfe/shared/schema-types';

export const hasEquipmentAlert = (
  indoorUnit: IndoorUnit | null,
  t: TFunction<string[]>
) =>
  indoorUnit?.deviceModel &&
  !(indoorUnit?.deviceModel?.toUpperCase() === t('contactInfo.modem.sb2'));

export const hexToRgba = (hex: string, alpha: number) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const shouldRenderAddonDisclosure = (kind: string) =>
  kind === ADDON_PRODUCT_KIND.staticIp ||
  STATIC_IP_ADDON_KINDS.includes(kind) ||
  kind === ADDON_PRODUCT_KIND.voice ||
  VOICE_ADDONS_KINDS.includes(kind);

export function getNextBillingLifecycle(billingAccount: BillingAccount) {
  const nextBillingCycleDate = billingAccount?.nextBillPeriodStartDate
    ? format(billingAccount?.nextBillPeriodStartDate, 'MMM dd')
    : '';
  return nextBillingCycleDate;
}

export function getSecondToLastDay(billingAccount: BillingAccount) {
  const previousBillingCycleDate = billingAccount?.nextBillPeriodStartDate
    ? format(
        subDays(new Date(billingAccount.nextBillPeriodStartDate), 1),
        'MMM dd'
      )
    : '';
  return previousBillingCycleDate;
}
