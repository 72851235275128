import { Storage } from '../index';
import { SESSION_STORAGE_VARIABLES } from './constants';

export const saveAlertNameInStorage = (key?: string) => {
  if (!key) {
    return;
  }
  const storageData =
    Storage.getItem(SESSION_STORAGE_VARIABLES.DISMISSED_ALERTS) ?? {};
  storageData[key] = 'true';

  Storage.setItem(SESSION_STORAGE_VARIABLES.DISMISSED_ALERTS, storageData);
};
