import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectScrubbedAddress } from '@mfe/to-be-migrated/redux/scrubbedAddress';

import { LoadingModal } from './loading';
import { SuggestedAddressModal } from './suggested-address';
import { ErrorAddressModal } from './error-address';
import { IncorrectAddressModal } from './incorrect-address';
import { ModalTypeEnum } from '../modal/modal';

export const ScrubAddressModal: FC<{
  isLoading: boolean;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: () => void;
  suggestedAddress: string[];
  namespace: string;
}> = ({
  isLoading,
  isModalOpen,
  handleClose,
  handleSave,
  suggestedAddress,
  namespace,
}) => {
  const { showModal } = useSelector(selectScrubbedAddress);

  if (isLoading) {
    return <LoadingModal ns={namespace} isOpen={isModalOpen} />;
  }

  switch (showModal) {
    case ModalTypeEnum.AddressError:
      return (
        <ErrorAddressModal
          ns={namespace}
          isOpen={isModalOpen}
          onClose={handleClose}
        />
      );
    case ModalTypeEnum.IncorrectAddress:
      return (
        <IncorrectAddressModal
          ns={namespace}
          isOpen={isModalOpen}
          onClose={handleClose}
        />
      );
    default:
      return (
        <SuggestedAddressModal
          ns={namespace}
          isOpen={isModalOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          suggestedAddress={suggestedAddress}
        />
      );
  }
};
