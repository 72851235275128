export const loadAdobeTargetScript = async (src: string, id: string) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    return new Promise(function (resolve, reject) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    }).catch((error) => console.error(`Issue loading: ${src}\n${error}`));
  } else {
    return new Promise((resolve) => resolve('script already exists'));
  }
};
