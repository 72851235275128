import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAddOnOffers,
  refetchAddOnOffers,
  refetchCurrentAddOns,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import {
  ShopAddons,
  useShouldRefetchCurrentAddons,
} from '@mfe/features/add-ons';
import { useShowAddonsShop } from '@mfe/shared/components';

const ShopAddonsPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showAddonsShop = useShowAddonsShop();
  const { addOnOrderId, submitOrder } = useSelector(selectAddOns);

  const shouldRefetchCurrentAddOns = useShouldRefetchCurrentAddons();

  const navigateToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  useEffect(() => {
    if (shouldRefetchCurrentAddOns) {
      dispatch(refetchCurrentAddOns(addOnOrderId as string));
    }

    if (addOnOrderId || submitOrder.error) {
      // Refetch to get a new offerId for the cart of the next order submission
      dispatch(refetchAddOnOffers());
    } else {
      dispatch(getAddOnOffers());
    }
  }, []);

  if (!showAddonsShop) {
    return <></>;
  }

  return <ShopAddons navigateToPreviousPage={navigateToPreviousPage} />;
};

export default ShopAddonsPage;
