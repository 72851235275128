import { EmptyState, Surface, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';

export const ActiveAddonsError = ({
  title,
  description,
  refreshButtonLabel,
}: {
  title: string;
  description: string;
  refreshButtonLabel: string;
}) => {
  const { isSmall, isExtraSmall } = useScreenResolution();

  return (
    <Surface
      variant="primary"
      radius={isSmall || isExtraSmall ? '0px' : '16px'}
      className={styles['container']}
    >
      <div id="customizable-error-container">
        <div className={styles['header']}>
          <Txt variant="bodyLargeBold">{title}</Txt>
        </div>
      </div>
      <EmptyState
        description={description}
        icon={Alert}
        actions={{
          primary: {
            label: refreshButtonLabel,
            onClick: () => window.location.reload(),
          },
        }}
      />
    </Surface>
  );
};
