export enum DisconnectSteps {
  INTRO = 'INTRO',
  RETENTION = 'RETENTION',
  REVIEW = 'REVIEW',
}

export type ModalProps = {
  isOpen: boolean;
  handleCancel?: () => void;
  handleContinue?: () => void;
};
