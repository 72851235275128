import {
  Button,
  Form,
  SectionAlert,
  StandAloneLink,
  Surface,
  TextField,
  Txt,
  validators,
} from '@vst/beam';
import React, { FormEvent, FormEventHandler, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';

import {
  fetchScrubbedAddress,
  selectScrubbedAddress,
} from '@mfe/to-be-migrated/redux/scrubbedAddress';
import { ScrubAddressModal } from '@mfe/shared/components';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ContactMethodLabels } from '@mfe/shared/schema-types';

import {
  formatAddressOnTwoLines,
  useAddressFormValues,
  useEmailFormValues,
  usePrepopulateContactInfo,
  useSaveInIra,
} from './utils';
import { renderMultilineLoading } from '../shared/renderMultilineLoading';

import styles from './styles.module.scss';
import { hasEquipmentAlert } from '../shared/utils';

enum ContactInfoSection {
  Address = 'Shipping Address',
  Email = 'Email Address',
}

type Section = ContactInfoSection | null;

export const ContactInformation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');

  const [showScrubModal, setShowScrubModal] = useState(false);
  const [editableSection, setEditableSection] = useState<Section>(null);
  const [loadingSection, setLoadingSection] = useState<Section>(null);

  const saveInIra = useSaveInIra();
  const { address, email, loading } = usePrepopulateContactInfo();
  const { inputAddress, handleAddressChange } = useAddressFormValues();
  const { inputEmail, handleEmailChange } = useEmailFormValues();

  const scrubAddressState = useSelector(selectScrubbedAddress);
  const { loading: scrubLoading, scrubbedAddress } = scrubAddressState;

  const { user } = useSelector(selectUser);
  const { indoorUnit } = user;
  const showEquipmentAlert = hasEquipmentAlert(indoorUnit, t);

  const handleAddressSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(fetchScrubbedAddress(inputAddress));
    setShowScrubModal(true);
  };

  const formattedAddress = useMemo(
    () => formatAddressOnTwoLines(scrubbedAddress.rawAddress),
    [scrubbedAddress.recommendation, scrubbedAddress.processStatus]
  );

  const closeAddressEditMode = () => {
    setShowScrubModal(false);
    setEditableSection(null);
  };

  const handleEmailSave = (e: FormEvent<HTMLFormElement>) => {
    setLoadingSection(ContactInfoSection.Email);
    e.preventDefault();
    const labels = [ContactMethodLabels.Invoice, ContactMethodLabels.Primary];
    const email = { email: inputEmail, labels };
    saveInIra({ email });
    setEditableSection(null);
  };

  const handleScrubSave = () => {
    setLoadingSection(ContactInfoSection.Address);
    const labels = [ContactMethodLabels.Shipping];
    const addresses = [{ ...scrubbedAddress.rawAddress, labels }];
    saveInIra({ addresses });
    closeAddressEditMode();
  };

  return (
    <div className={styles['contactInformation']}>
      <div>
        <Txt variant="bodyLargeBold" mb="8px">
          {t('contactInfo.title')}
        </Txt>
        <Txt variant="bodySmallRegular">{t('contactInfo.description')}</Txt>
      </div>
      <hr className={styles['separator']} />
      <ContactInfoCard
        title={t('contactInfo.shippingAddress.label')}
        loading={loading && loadingSection === ContactInfoSection.Address}
        values={address}
        isEditable={editableSection === ContactInfoSection.Address}
        handleEdit={() => setEditableSection(ContactInfoSection.Address)}
        handleSave={handleAddressSave}
        handleCancel={closeAddressEditMode}
        formId="address-form"
      >
        <EditableAddress
          handleSubmit={handleAddressSave}
          handleChange={handleAddressChange}
        />
      </ContactInfoCard>
      {showScrubModal && (
        <ScrubAddressModal
          isLoading={scrubLoading}
          isModalOpen={showScrubModal}
          handleClose={() => setShowScrubModal(false)}
          handleSave={handleScrubSave}
          suggestedAddress={formattedAddress}
          namespace="ScrubAddress"
        />
      )}
      <ContactInfoCard
        title={t('contactInfo.email.label')}
        loading={loading && loadingSection === ContactInfoSection.Email}
        values={[email]}
        isEditable={editableSection === ContactInfoSection.Email}
        handleEdit={() => setEditableSection(ContactInfoSection.Email)}
        handleSave={handleEmailSave}
        handleCancel={() => setEditableSection(null)}
        formId="email-form"
      >
        <EditableEmail
          handleSubmit={handleEmailSave}
          handleChange={handleEmailChange}
        />
      </ContactInfoCard>
      {showEquipmentAlert && (
        <SectionAlert className={styles['sectionAlert']}>
          <div>
            <Txt component="span" variant="smallBold" color="regular">
              {t('contactInfo.notice')}
            </Txt>
            <Txt component="span" variant="smallRegular" color="regular">
              {t('contactInfo.alert')}
            </Txt>
          </div>
        </SectionAlert>
      )}
    </div>
  );
};

type ContactInfoCardProps = {
  title: string;
  loading: boolean;
  values: string[];
  isEditable: boolean;
  children: React.ReactNode;
  formId: string;
  handleEdit: () => void;
  handleCancel: () => void;
  handleSave: (e: FormEvent<HTMLFormElement>) => void;
};

const ContactInfoCard = ({
  title,
  loading,
  values,
  isEditable,
  children,
  formId,
  handleEdit,
  handleCancel,
}: ContactInfoCardProps) => {
  const { t } = useTranslation('Disconnect');

  const handleEditSection: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    handleEdit();
  };

  const prepopulatedValues = loading ? (
    <div style={{ width: '50%', marginTop: 8 }}>
      {renderMultilineLoading(2)}
    </div>
  ) : (
    values.map((value: string, index) => (
      <Txt
        key={index}
        variant="bodySmallRegular"
        style={{ overflowWrap: 'anywhere' }}
      >
        {value}
      </Txt>
    ))
  );

  return (
    <Surface
      p="16px"
      radius="8px"
      variant="secondary"
      className={styles['contactInfoCard']}
    >
      <div style={{ width: '100%' }}>
        <Txt variant="labelSmall" mb="4px">
          {title}
        </Txt>
        {isEditable ? (
          <>
            {children}
            <EditActions formId={formId} handleCancel={handleCancel} />
          </>
        ) : (
          prepopulatedValues
        )}
      </div>
      {!isEditable && (
        <StandAloneLink href="#" onClick={handleEditSection} icon={Edit}>
          {t('contactInfo.button.edit')}
        </StandAloneLink>
      )}
    </Surface>
  );
};

const EditActions = ({
  handleCancel,
  formId,
}: {
  handleCancel: () => void;
  formId: string;
}) => {
  const { t } = useTranslation('Disconnect');

  return (
    <div className={styles['editActions']}>
      <Button variant="secondary" onClick={handleCancel}>
        {t('contactInfo.button.cancel')}
      </Button>
      <Button type="submit" form={formId}>
        {t('contactInfo.button.save')}
      </Button>
    </div>
  );
};

const EditableAddress = ({
  handleSubmit,
  handleChange,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleChange: (event: FormEvent<HTMLInputElement>, field: string) => void;
}) => {
  const { t } = useTranslation('Disconnect', { keyPrefix: 'contactInfo' });

  const getValidationRules = (fieldName: string) => [
    validators.required({ message: t('requiredField', { fieldName }) }),
  ];

  return (
    <Form
      id="address-form"
      validationMode="onChange"
      onSubmit={handleSubmit}
      className={styles['editAddressForm']}
    >
      <div className={styles['editAddressFormGroup']}>
        <TextField
          required
          validationRules={getValidationRules(t('shippingAddress.street'))}
          fluid
          id="streetAddress"
          name="streetAddress"
          labelProps={{ labelText: t('shippingAddress.street') }}
          onChange={(e) => handleChange(e, 'streetAddress')}
        />
        <TextField
          fluid
          id="apt"
          name="apt"
          labelProps={{ labelText: t('shippingAddress.apt') }}
          onChange={(e) => handleChange(e, 'apt')}
        />
      </div>
      <div className={styles['editAddressFormGroup']}>
        <TextField
          required
          validationRules={getValidationRules(t('shippingAddress.city'))}
          fluid
          id="city"
          name="city"
          labelProps={{ labelText: t('shippingAddress.city') }}
          onChange={(e) => handleChange(e, 'city')}
        />
        <TextField
          required
          validationRules={getValidationRules(t('shippingAddress.state'))}
          fluid
          id="state"
          name="state"
          labelProps={{ labelText: t('shippingAddress.state') }}
          onChange={(e) => handleChange(e, 'state')}
        />
        <TextField
          required
          validationRules={getValidationRules(t('shippingAddress.postalCode'))}
          fluid
          id="postalCode"
          name="postalCode"
          labelProps={{ labelText: t('shippingAddress.postalCode') }}
          onChange={(e) => handleChange(e, 'postalCode')}
        />
      </div>
    </Form>
  );
};

const EditableEmail = ({
  handleSubmit,
  handleChange,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleChange: (event: FormEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation('Disconnect', { keyPrefix: 'contactInfo' });

  const validationRules = [
    validators.required({
      message: t('requiredField', { fieldName: 'Email' }),
    }),
  ];

  return (
    <Form
      validationMode="onSubmit"
      onSubmit={handleSubmit}
      id="email-form"
      className={styles['emailForm']}
    >
      <TextField
        validationRules={validationRules}
        required
        mt="8px"
        mb="16px"
        fluid
        id="email"
        name="email"
        onChange={handleChange}
      />
    </Form>
  );
};
