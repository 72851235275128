import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonLink, Accordion, AccordionGroup, Txt } from '@vst/beam';

import useNavigate from '@mfe/services/navigation';
import { AddOnCard } from '@mfe/features/add-ons';
import { SESSION_STORAGE_VARIABLES, Storage } from '@mfe/shared/util';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { ActiveAddonsError } from '@mfe/shared/components';

import styles from './index.module.scss';

const SubscribedAddons = () => {
  const { t } = useTranslation(['ShopAddons', 'Profile']);
  const { goTo } = useNavigate();

  const { currentAddOns, error } = useSelector(selectAddOns);

  if (!currentAddOns?.length) {
    return null;
  }

  if (error) {
    return (
      <div className={styles['subscribed-addons-error']}>
        <ActiveAddonsError
          title={t('ShopAddons:myAddons.title')}
          description={t('Profile:addOns.error.description')}
          refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
        />
      </div>
    );
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <AccordionGroup>
          <Accordion
            title={
              <Txt variant="labelLarge">{t('ShopAddons:myAddons.title')}</Txt>
            }
          >
            <div className={styles['addons-wrapper']}>
              {currentAddOns && <Content />}
              <div className={styles['manage-addons-link']}>
                <ButtonLink
                  data-cy="manage-my-addons-link"
                  linkSize="medium"
                  onClick={(e) => {
                    e.preventDefault();
                    Storage.setItem(
                      SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB,
                      true
                    );
                    goTo('Profile');
                  }}
                  arrowPos={'forward'}
                  href="#"
                  variant={'primary'}
                  label={t('ShopAddons:myAddons.manageAddonsButtonLabel')}
                />
              </div>
            </div>
          </Accordion>
        </AccordionGroup>
      </div>
    </div>
  );
};

const Content = () => {
  const { currentAddOns, loading } = useSelector(selectAddOns);

  if (!currentAddOns) {
    return null;
  }

  // On post-purchase refetch
  if (currentAddOns && loading) {
    return (
      <div className={styles['loading-subscribed-addons']}>
        <LoadingCard />
        <LoadingCard />
      </div>
    );
  }

  return (
    <div className={styles['addons']}>
      {currentAddOns?.map((addon, index) => (
        <div key={index} className={styles['addons__item']}>
          <AddOnCard addon={addon}>
            {addon.description && (
              <Txt variant="smallRegular" color="regular">
                {addon.description}
              </Txt>
            )}
          </AddOnCard>
        </div>
      ))}
    </div>
  );
};

const LoadingCard = () => {
  return (
    <div className={styles['loading-card']}>
      <div className={styles['loading-icon']} />
      <div className={styles['loading-content']}>
        <div className={styles['loading-title']} />
        <div className={styles['loading-description']} />
      </div>
    </div>
  );
};

export default SubscribedAddons;
