import { gql } from '@apollo/client';
import {
  CHARACTERISTICS_FRAGMENT,
  MARKETING_COPY_FRAGMENT,
} from '../../fragments';

export const GET_ETF = gql`
  query getEarlyTerminationFees {
    getEarlyTerminationFees {
      accountNumber
      calculatedEtf {
        value
      }
      etfPerDayCurrMonth {
        value
      }
      etfPerMonth {
        value
      }
      etfRemainingDaysCurrMonth {
        value
      }
      etfRemainingMonths {
        value
      }
      productInstanceId
      remainingDaysCurrMonth
      remainingMonthsInContract
      totalMonthsInContract
    }
  }
`;

export const GET_RETENTION_OFFERS = gql`
  query getRetentionOffers {
    getRetentionOffers {
      # characteristics {
      # ...CHARACTERISTICS_FRAGMENT
      # }
      description
      id
      kind
      name
      offer_id
      # marketing_copy {
      #  ...MARKETING_COPY_FRAGMENT
      # }
      #   prices {
      #     amount {
      #       value
      #     }
      #   }
      #   package_types {
      #     name
      #   }
      # discounts {
      #   total_discounts {
      #     name
      #     duration
      #     amount {
      #       value
      #       currency
      #     }
      #   }
      #   itemized_discounts {
      #     name
      #     duration
      #     amount {
      #       value
      #       currency
      #     }
      #   }
      # }
    }
  }
`;

export const PURCHASE_RETENTION_OFFER = gql`
  mutation purchaseRetentionOffer($input: PurchaseRetentionOfferInput!) {
    purchaseRetentionOffer(input: $input) {
      success
      message
    }
  }
`;

export const DELETE_VIASAT_INTERNET = gql`
  mutation deleteViasatInternet($input: DeleteViasatInternetInput!) {
    deleteViasatInternet(input: $input) {
      status
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      success
      message
    }
  }
`;

export const GET_SCHEDULED_FOR_DELETION_ORDERS = gql`
  query getScheduledForDeletionOrders {
    getScheduledForDeletionOrders {
      orderId
    }
  }
`;
