import { Address, Coordinates } from '@mfe/shared/schema-types';

export const sanitizeAddressLines = (
  address: Address,
  coordinates: Coordinates
) => {
  const { addressLines } = address;

  return {
    address: {
      addressLines: addressLines[1] ? addressLines : [addressLines[0]],
      countryCode: address.countryCode,
      municipality: address.municipality,
      postalCode: address.postalCode,
      region: address.region,
    },
    coordinates: {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    },
  };
};
