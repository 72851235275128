import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Icon, Surface, Txt } from '@vst/beam';
import { ShoppingCart } from '@vst/beam-icons/icons';

import { Collapsible } from '@mfe/shared/components';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { selectEtf } from '@mfe/to-be-migrated/redux/disconnect';

import { DisconnectionCart } from './disconnection-cart';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { getNextBillingLifecycle } from './utils';

export const CollapsibleDisconectCart = () => {
  return (
    <Collapsible
      dataCy="collapsible-disconnection-cart"
      classNames={{ summary: styles['summaryBackground'] }}
      summary={<Summary />}
      content={<Content />}
    />
  );
};

const Summary = () => {
  const { t } = useTranslation('Disconnect');
  const { data } = useSelector(selectEtf);
  const totalEtf = data?.calculatedEtf?.value ?? '';

  const billingAccount = useSelector(selectBillingAccount);
  const serviceEndLoading = billingAccount?.loading;
  const nextBillingCycleDate = getNextBillingLifecycle(billingAccount);

  return (
    <Surface className={styles['summaryContent']}>
      <div>
        <Icon
          data-cy="shopping-cart-icon"
          icon={ShoppingCart}
          size={24}
          color="gray_600"
        />
        {serviceEndLoading ? (
          <div className={styles['loadingRow']} />
        ) : (
          <>
            <Txt variant="smallRegular" mr="4px">
              {t('cart.serviceEnd')}
            </Txt>
            <Txt variant="smallBold">{nextBillingCycleDate}</Txt>
          </>
        )}
      </div>
      {totalEtf && (
        <div>
          <Txt variant="smallRegular" mr="4px">
            {t('cart.oneTimeFee')}
          </Txt>
          <Txt variant="smallBold">${totalEtf}</Txt>
        </div>
      )}
    </Surface>
  );
};

const Content = () => {
  return (
    <Surface className={styles['cartDetails']}>
      <DisconnectionCart />
    </Surface>
  );
};
